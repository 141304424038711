import UneditedMessageWarning from '@apps/registry/common/components/ShoppingCart/components/UneditedMessageWarning';
import { DonationFundPaymentMethodEnum, EventType } from '@graphql/generated';
import { Checkbox, generateGiftWrapPrompt, getGiftWrapThemeListByEventType } from '@shared/components';

import { GiftWrapEditor, useGiftWrapECardPrice } from '@shared/components/GiftWrap';
import { useTranslation } from '@shared/core';
import { useEventInfo } from '@shared/utils/eventInfo';
import { SpacingStack, TextV2, Box, Flex, Divider, TextArea, useDisclosure } from '@withjoy/joykit';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { CheckboxContainer, styles } from './GiftWrapOffer.styles';
import { useFeatureValue } from '@shared/core/featureFlags';

type CallbackProps = { message: string; themeId: string; currentTab: string };

interface GiftWrapOfferProps {
  initialMessage?: string;
  skip?: boolean;
  giverName: string;
  giftName: string;
  product?: {
    title: string;
    image?: string;
    store?: string;
    extraProductsCount?: number;
    platform?: DonationFundPaymentMethodEnum;
  };
  giftWrapEditMessageClicked: (data: { label: string }) => void;
  giftWrapPromptSent: (data: { prompt: string }) => void;
  giftWrapMessageEdited: (data: { originalNote: string; modifiedNote: string }) => void;
  giftWrapUneditedMessageShown: () => void;
  viewMoreDesignsClicked: (show: boolean) => void;
  onSetThemeId: (id: string, defaultThemeID: string, themeHistory: string[], themeOrder: string[]) => void;
  children?: (data: { onClick: () => void }) => React.ReactElement;
  onButtonClick: (data: CallbackProps) => unknown;
  onContinue?: () => void;
}

const GiftWrapOffer: React.FC<GiftWrapOfferProps> = ({
  initialMessage,
  skip,
  giverName,
  giftName,
  product,
  giftWrapEditMessageClicked,
  giftWrapPromptSent,
  giftWrapMessageEdited,
  giftWrapUneditedMessageShown,
  viewMoreDesignsClicked,
  children,
  onButtonClick,
  onContinue,
  onSetThemeId
}) => {
  const PRICE = useGiftWrapECardPrice();
  const { eventInfo } = useEventInfo();
  const [tab, setTab] = useState<'wrap' | 'message'>('wrap');
  const [savedWrapMessage, saveWrapMessage] = useState('');
  const [message, setMessage] = useState(initialMessage || '');
  const themes = getGiftWrapThemeListByEventType(eventInfo?.eventType);
  const [themeId, setThemeId] = useState<string>('');
  const { t } = useTranslation('guestRegistry');
  const tShoppingCart = t('shoppingCart');
  const defaultThemeIDRef = useRef<string>('');
  const themeHistoryRef = useRef<string[]>([]);
  const themeOrderRef = useRef<string[]>([]);
  const [isMessageEdited, setIsMessageEdited] = useState(false);
  const uneditedMessageWarning = useDisclosure();
  const { value: randomizeThemeOrder } = useFeatureValue('registryGiftWrapRandomizeThemeOrder');

  const handleWrapClick = () => {
    if (tab === 'wrap') {
      return;
    }

    setTab('wrap');
    handleWrapMessageChange(savedWrapMessage);
  };

  const handleMessageClick = (e: SyntheticEvent<unknown>) => {
    if (tab === 'message') {
      return;
    }
    // we are navigating from gift wrap to basic, so clear the eCard info
    e.stopPropagation();
    e.preventDefault();
    setTab('message');
    setMessage('');
  };

  const handleWrapMessageChange = (message: string) => {
    saveWrapMessage(message);
    setMessage(message);
  };

  const handleBlur = ({ originalNote, message }: { originalNote: string; message: string }) => {
    giftWrapMessageEdited({ originalNote, modifiedNote: message });
    setIsMessageEdited(true);
  };

  const handleSetThemeId = (themeId: string, defaultThemeID: string, themeHistory: string[], themeOrder: string[]) => {
    defaultThemeIDRef.current = defaultThemeID;
    themeHistoryRef.current = themeHistory;
    themeOrderRef.current = themeOrder;
    onSetThemeId?.(themeId, defaultThemeID, themeHistory, themeOrder);
    setThemeId(themeId);
  };

  const handleButtonClick = () => {
    if (!isMessageEdited && tab === 'wrap') {
      uneditedMessageWarning.onOpen();
      giftWrapUneditedMessageShown();
      return false;
    }

    onButtonClick({ message, themeId, currentTab: tab });
    return true;
  };

  const handleContinueClick = () => {
    uneditedMessageWarning.onClose();
    onButtonClick({ message, themeId, currentTab: tab });
    onContinue?.();
  };

  const isgiftWrapOptionsOrderReversed = useFeatureValue('giftWrapOptionsOrderExperiment')?.value === 'treatment';

  return (
    <SpacingStack spacing={3} paddingX={0} paddingTop={0} maxWidth="475px" width="100%">
      <UneditedMessageWarning isOpen={uneditedMessageWarning.isOpen} onClose={uneditedMessageWarning.onClose} onContinue={handleContinueClick} />
      <TextV2 typographyVariant="label3">{tShoppingCart.messageStep.title()}</TextV2>
      <Flex flexDirection={isgiftWrapOptionsOrderReversed ? 'column-reverse' : 'column'}>
        <Box border="1px solid" borderColor="mono3" borderRadius="4px" paddingTop={1}>
          <Flex alignItems="center" padding={5} role="button" onClick={handleWrapClick} cursor="pointer">
            <CheckboxContainer>
              <Checkbox id="card" checked={tab === 'wrap'} onChange={handleWrapClick} />
            </CheckboxContainer>
            <Flex flexDirection="column" marginLeft={5} flexGrow={1}>
              <Flex justifyContent="space-between" marginBottom={1}>
                <TextV2 typographyVariant="button1">{tShoppingCart.messageStep.wrapTitle()}</TextV2>
                <TextV2 typographyVariant="label2" color="mono10">
                  ${PRICE}
                </TextV2>
              </Flex>
              <TextV2 typographyVariant="label2" color="mono12">
                {tShoppingCart.messageStep.wrapDescription()}
              </TextV2>
            </Flex>
          </Flex>
          {tab === 'wrap' && (
            <Box maxWidth={'500px'}>
              <Flex paddingBottom={5} paddingX={5}>
                <Divider />
              </Flex>
              <GiftWrapEditor
                onEditMessageClick={label => giftWrapEditMessageClicked({ label })}
                onPromptSent={prompt => giftWrapPromptSent({ prompt })}
                onBlur={handleBlur}
                skip={!!savedWrapMessage || skip}
                product={product}
                prompt={generateGiftWrapPrompt({
                  from: giverName,
                  firstPartner: eventInfo?.ownerFirstName || '',
                  secondPartner: eventInfo?.fianceeFirstName || '',
                  giftName,
                  context: eventInfo?.eventType === EventType.babyRegistry ? 'baby' : 'wedding'
                })}
                message={message}
                setMessage={handleWrapMessageChange}
                themes={themes}
                setThemeId={handleSetThemeId}
                onViewMoreThemesClick={viewMoreDesignsClicked}
                randomizeThemeOrder={!!randomizeThemeOrder}
              />
            </Box>
          )}
        </Box>
        <Box __css={styles.basicGiftNoteContainer}>
          <Flex alignItems="center" padding={5} role="button" onClick={handleMessageClick} cursor="pointer">
            <CheckboxContainer>
              <Checkbox id="basic" checked={tab === 'message'} onChange={handleMessageClick} />
            </CheckboxContainer>
            <TextV2 typographyVariant="button1" marginLeft={5}>
              {tShoppingCart.messageStep.noteTitle()}
            </TextV2>
          </Flex>
          {tab === 'message' && (
            <Box paddingBottom={5} paddingX={5}>
              <Flex paddingBottom={5}>
                <Divider />
              </Flex>
              <TextV2 typographyVariant="label3" marginBottom={3}>
                {tShoppingCart.messageStep.textareaLabel()}
              </TextV2>
              <TextArea
                maxLength={product?.platform === DonationFundPaymentMethodEnum.venmo ? 280 : undefined}
                placeholder={tShoppingCart.messageStep.textAreaPlaceholder()}
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </Box>
          )}
        </Box>
      </Flex>
      {children?.({ onClick: handleButtonClick })}
    </SpacingStack>
  );
};

export default GiftWrapOffer;

import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { config } from '@static/js/env.config';
import { ShippingAddressFragment } from '@graphql/aliases';
import { withWindow } from '@shared/utils/withWindow';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useFeatureValue } from '@shared/core/featureFlags';
import { DEFAULT_CURRENCY_SYMBOL } from '@shared/utils/currency/constants';
import { getCurrencySymbolFromCurrencyCode } from '@shared/utils/currency/utils';
import { RegistryOrderList, RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { CookedProduct, isCustomDonationFundItem, isPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { ProductListView } from './ProductListView';
import { RegistryLogoGrid } from '../RegistryLogoGrid';
import { RegistryOpenHandler } from '../types';
import { useStoredGiftReservationFieldsContext } from '../StoredGiftReservationFieldsProvider';
import { useShoppingCartController } from '../../../../../common/components/ShoppingCart/ShoppingCart.controller';
import { ToastVariableProps } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { useProductListContainerController } from '@apps/registry/guest/routes/GuestRegistry/components/ProductList/ProductListContainer.controller';
import { PurchaseDialog } from '../PurchaseDialog';
import { ChangeEmailDialog } from '../ChangeEmailDialog';
import { SyncedProductDialog } from '../SyncedProductDialog';
import { GiveGiftDialog, useGiveGiftDialog } from '../GiveGiftDialog';
import { useCheckoutDialogContext } from '@apps/registry/guest/components/CheckoutDialog';
import { ReserveItemDialog } from '@apps/registry/guest/routes/GuestRegistry/components/ReserveItemDialog';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { GetItemCheckoutMechanismsDocument, GetItemCheckoutMechanismsQuery, RegistryType } from '@graphql/generated';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from '@shared/core';
import { useUpdateSearchParam } from '@shared/utils/updateSearchParam';
import { useQueryParamHelper } from '@shared/core/queryString';

interface Props
  extends Readonly<{
    registries: RegistryList;
    orders: RegistryOrderList;
    eventId: string;
    eventPhoto?: string;
    showToast?: () => void;
    isShowCartSection?: boolean;
    shippingAddress: ShippingAddressFragment | null;
    openToast: (toast: ToastVariableProps) => void;
    eventHandle: string;
  }> {}

const productIdQueryParam = 'pid';

export const ProductListContainer: React.FC<Props> = ({ eventHandle, registries, orders, eventId, eventPhoto, openToast, showToast, isShowCartSection, shippingAddress }) => {
  const [currentProduct, setCurrentProduct] = useState<CookedProduct>();
  const [showPurchaseDialog, setShowPurchaseDialog] = useState<boolean>(false);
  const [showReserveItemDialog, setShowReserveItemDialog] = useState<boolean>(false);
  const client = useApolloClient();
  const updateSearchParam = useUpdateSearchParam();

  const { t2 } = useTranslation('sharedRegistry');
  const { msrpTitle, msrpDescription } = t2('giftDialog');

  const customItemsRef = useRef<HTMLDivElement>(null);

  // TODO: remove for registry hide feature release
  const enableCashFundCheckoutV2 = useFeatureValue('enableCashFundCheckoutV2');
  const { externalRegistry, registryItem } = useGuestRegistryTelemetry();
  const { eventInfo } = useEventInfo();
  const giveGiftDialogProps = useGiveGiftDialog({ product: currentProduct, showToast });
  const { openCheckoutDialog } = useCheckoutDialogContext();
  const storedGiftReservationFields = useStoredGiftReservationFieldsContext();
  const eventFirebaseId = eventInfo?.eventFirebaseId;

  const { reservedOrderIds, getReservedOrderByProductId, purchasedOrderIds, productList: cookedProductList } = useShoppingCartController({
    registries,
    orders
  });

  const queryParamHelper = useQueryParamHelper();

  const handleClickRegistry = useCallback<RegistryOpenHandler>(
    ({ id, url, product }, fireExternalClick = true) => {
      if (fireExternalClick) {
        const isPriced = isPricedProduct(product);
        externalRegistry({
          id,
          priceValueInMinorUnits: isPriced ? product.valueInMinorUnits : 0,
          priceCurrencyCode: isPriced ? getCurrencySymbolFromCurrencyCode(product.currencyCode) : DEFAULT_CURRENCY_SYMBOL,
          reservedQty: product ? product.reserved : undefined,
          destinationUrl: product ? product.checkoutUrl : undefined,
          typeOfItem: product ? product.registryItemType : undefined
        });
      }

      const path = queryString.stringifyUrl({
        url: `${config.redirectServiceUri}/redirect`,
        query: {
          url,
          platform: 'web',
          eventId: eventId
        }
      });

      withWindow(window => window.open(path, '_blank'));
    },
    [eventId, externalRegistry]
  );

  const standaloneRegistriesList = useMemo(() => {
    return registries?.filter(registry => registry.id === eventId)[0];
  }, [registries, eventId]);

  const mySessionEmail = standaloneRegistriesList?.mySession?.email;

  const [productDialogIsOpen, setProductDialogIsOpen] = useState(false);
  const showProductDialog = () => {
    setProductDialogIsOpen(true);
  };
  const closeProductDialog = () => {
    setProductDialogIsOpen(false);
  };

  const closePurchaseDialog = () => setShowPurchaseDialog(false);
  const closeReserveItemDialog = () => setShowReserveItemDialog(false);
  const { openPurchaseConfirmationModal } = useProductListContainerController();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductDialogOpen = useCallback(
    async (product: CookedProduct, isReserved?: boolean, isPurchased?: boolean) => {
      setCurrentProduct(product);
      const isDropShippable = (
        await client.query<GetItemCheckoutMechanismsQuery>({
          query: GetItemCheckoutMechanismsDocument,
          variables: {
            id: product.id
          }
        })
      ).data.registryItem?.productData.checkoutMechanisms.some(i => i.type === 'joyWeb');
      const stillNeeded: number = product.donationFund ? product.donationFund.remaining : product.stillNeeded;
      const goal: number = product.donationFund ? product.donationFund.goal : product.requested;
      const isReservedByYou = isReserved || reservedOrderIds?.some(item => product.id === item);
      const isPurchasedByYou = isPurchased || purchasedOrderIds?.some(item => product.id === item);
      if (!product.externallyOwned || isDropShippable) {
        if (goal !== 0 && stillNeeded <= 0) {
          const reservedOrder = getReservedOrderByProductId(product.id);
          if (isReservedByYou && reservedOrder) {
            openPurchaseConfirmationModal({ product, reservedOrder });
          } else if (!isPurchasedByYou) {
            setShowReserveItemDialog(true);
          }
        } else {
          if (isCustomDonationFundItem(product)) {
            if (enableCashFundCheckoutV2) {
              openCheckoutDialog({ registryItemId: product.registryItemId });
            } else {
              giveGiftDialogProps.handleOnOpen();
            }
          } else {
            setShowPurchaseDialog(true);
          }
        }
      } else {
        showProductDialog();
      }
      setIsModalOpen(true);
    },
    [client, enableCashFundCheckoutV2, getReservedOrderByProductId, giveGiftDialogProps, openCheckoutDialog, openPurchaseConfirmationModal, purchasedOrderIds, reservedOrderIds]
  );

  const handleClickProduct = useCallback(
    async (product: CookedProduct, isReservedByYou?: boolean, isPurchasedByYou?: boolean) => {
      const pid = queryParamHelper.getValueString(productIdQueryParam) || '';
      if (pid !== product.id) {
        updateSearchParam(productIdQueryParam, product.id);
      }
      handleProductDialogOpen(product, isReservedByYou, isPurchasedByYou);
      const stillNeeded: number = product.donationFund ? product.donationFund.remaining : product.stillNeeded;
      const goal: number = product.donationFund ? product.donationFund.goal : product.requested;
      const registryItemState = goal !== 0 && stillNeeded <= 0 ? 'ReservedRegistryItem' : 'RegistryItem';
      const generatedLabel = product.generatedLabel;
      registryItem({
        productTitle: product.title,
        registryItemId: product.registryItemId,
        registryId: product?.registry.id,
        registryItemState,
        generatedLabel,
        ourMostWanted: product.mustHave
      });
    },
    [handleProductDialogOpen, queryParamHelper, registryItem, updateSearchParam]
  );

  const registryLogosToShow = registries?.filter(registry => !registry.isTransferredType && registry.id !== eventId && registry.type !== RegistryType.adopted);
  const otherRegistriesCount = registryLogosToShow?.length || 0;

  const {
    counts: { everything }
  } = cookedProductList;

  const productList = useMemo(() => {
    return [
      ...cookedProductList.products.filter(product => !(product.externallyOwned && product.stillNeeded <= 0)),
      ...cookedProductList.products.filter(product => product.externallyOwned && product.stillNeeded <= 0)
    ];
  }, [cookedProductList]);

  useEffect(() => {
    const pid = queryParamHelper.getValueString(productIdQueryParam) || '';
    if (pid && !isModalOpen) {
      const product = productList.find(item => item.id === pid);
      if (product) {
        handleProductDialogOpen(product);
      }
    }
  }, [handleProductDialogOpen, isModalOpen, productList, queryParamHelper]);

  const onClickCustomItem = () => {
    withWindow(global => {
      global.scrollTo({ top: customItemsRef.current?.offsetTop, behavior: 'smooth' });
    });
  };

  return (
    <>
      <ReserveItemDialog product={currentProduct} eventId={eventFirebaseId} isOpen={showReserveItemDialog} onClose={closeReserveItemDialog} />
      {everything > 0 && (
        <div ref={customItemsRef}>
          <ProductListView
            productList={productList}
            handleClickProduct={handleClickProduct}
            reservedOrderList={reservedOrderIds}
            purchasedOrderList={purchasedOrderIds}
            isShowCartSection={isShowCartSection}
          />
        </div>
      )}
      {otherRegistriesCount > 0 && (
        <RegistryLogoGrid logosToShow={registryLogosToShow} handleClickRegistry={handleClickRegistry} onClickCustomItem={onClickCustomItem} product={currentProduct} />
      )}
      {currentProduct && showPurchaseDialog && (
        <PurchaseDialog
          eventHandle={eventHandle}
          isDialogOpen={showPurchaseDialog}
          closeDialog={closePurchaseDialog}
          product={currentProduct}
          eventId={eventId}
          eventInfoState={eventFirebaseId}
          eventPhoto={eventPhoto}
          openToast={openToast}
          mySessionEmail={mySessionEmail}
          shippingAddress={shippingAddress}
          msrpTranslations={{ msrpTitle, msrpDescription }}
        />
      )}
      {!enableCashFundCheckoutV2 && (
        <GiveGiftDialog {...giveGiftDialogProps} existingOrderId={null} product={currentProduct} eventId={eventFirebaseId} mySessionEmail={mySessionEmail} />
      )}
      <ChangeEmailDialog
        isOpen={storedGiftReservationFields.isChangeEmailDialogOpen}
        onClose={storedGiftReservationFields.handleOnChangeEmailDialogClose}
        onSubmitEmail={storedGiftReservationFields.handleOnChangeEmailDialogSubmit}
        mySessionEmail={mySessionEmail || ''}
      />
      <SyncedProductDialog product={currentProduct} eventId={eventFirebaseId} isOpen={productDialogIsOpen} onClose={closeProductDialog} />
    </>
  );
};

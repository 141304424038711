import { isProductFullyPurchased, ItemWithPrice, isCustomDonationFundItem } from '@apps/registry/common/selectors/ProductListSelector';
import { RegistryOrderList } from '@apps/registry/common/state/RegistryProducts';
import { useTranslation } from '@shared/core';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { useMemo } from 'react';

const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const useProgressTrackerTranslations = (product: ItemWithPrice, orders: RegistryOrderList) => {
  const { t, t2 } = useTranslation('yourRegistry');
  const { formatCurrency } = useCurrencyFormatter();

  return useMemo(() => {
    const translations = t('giftProgressTracker');
    const { currencyCode, numberPrice, purchased, requested, stillNeeded, floatingPointDecimalString, donationFund } = product;
    const contributionAnyAmount = donationFund && !donationFund?.fund.suggestedDonationMonetaryValue;
    const contributionAmount =
      donationFund && donationFund?.fund.suggestedDonationMonetaryValue?.valueInMinorUnits
        ? donationFund?.fund.suggestedDonationMonetaryValue?.floatingPointDecimalString
        : floatingPointDecimalString;
    const formattedTotalPrice = formatCurrency({
      priceFloatingPointDecimalString: floatingPointDecimalString,
      priceCurrencyCode: currencyCode,
      formatForm: 'short'
    });
    const contributionAmountString = contributionAnyAmount
      ? translations.contributionAnyAmountText()
      : formatCurrency({
          priceFloatingPointDecimalString: contributionAmount,
          priceCurrencyCode: currencyCode,
          formatForm: 'short'
        });
    let titleText: string;
    let progressText: string;
    let groupGiftingProgressText = '';
    let remainingText: string;
    let contributedText: string = '';
    let formattedProgressAmount = '';
    const hasPurchases = product.purchased > 0;
    const isCompleted = isProductFullyPurchased(product);
    const goalText = translations.cashGiftLabel({ goal: formattedTotalPrice });
    const goalReachedText = translations.cashGiftLabelComplete({ goal: formattedTotalPrice });
    const contributionText = contributionAnyAmount ? contributionAmountString : translations.contributionText({ amount: contributionAmountString });
    const isCashOrCharityFund = isCustomDonationFundItem(product);

    let orderCount = 0;
    orders?.map(order => {
      const lineItem = order && order.lineItems.find(lineItem => lineItem.quantity);
      if (lineItem?.quantity) {
        orderCount = lineItem.quantity + orderCount;
      }
    });

    if (isCashOrCharityFund || product.isGroupGiftingEnabled) {
      titleText = isCompleted ? translations.cashGiftLabelComplete({ goal: formattedTotalPrice }) : translations.cashGiftLabel({ goal: formattedTotalPrice });
      let progressAmount: number;
      let remainingAmount: number;

      if (donationFund) {
        // NOTE: considers fixed/open donations as the same
        progressAmount = donationFund.received;
        remainingAmount = donationFund.remaining;
      } else {
        progressAmount = numberPrice * purchased;
        remainingAmount = numberPrice * stillNeeded;
      }

      formattedProgressAmount = formatCurrency({
        priceFloatingPointDecimalString: progressAmount.toString(),
        priceCurrencyCode: currencyCode,
        formatForm: 'short'
      });
      const formattedRemainingAmount = formatCurrency({
        priceFloatingPointDecimalString: remainingAmount.toString(),
        priceCurrencyCode: currencyCode,
        formatForm: 'short'
      });

      contributedText = translations.contributedText({ amount: formattedProgressAmount });
      progressText = translations.cashGiftProgress({ amount: formattedProgressAmount });
      groupGiftingProgressText = translations.groupGiftProgress({ amount: formattedProgressAmount });
      remainingText = translations.remainingText({ amount: formattedRemainingAmount });
    } else {
      titleText = isCompleted ? translations.physicalGiftLabelComplete({ requested }) : translations.physicalGiftLabel({ requested });
      progressText = translations.physicalGiftProgress({ purchased: orderCount, requested });
      remainingText = translations.remainingText({ amount: stillNeeded });
    }

    if (!hasPurchases) {
      const startStrings = t2('giftProgressTracker', 'progressBarStateStart');
      progressText = startStrings[randomInteger(0, Object.keys(startStrings).length - 1)];
    }

    const completedStrings = t2('giftProgressTracker', 'progressBarStateComplete');
    if (isCompleted) {
      remainingText = completedStrings[randomInteger(0, Object.keys(completedStrings).length - 1)];
    }

    return {
      titleText,
      progressText,
      groupGiftingProgressText,
      remainingText,
      goalText,
      goalReachedText,
      contributionText,
      contributedText,
      contributionAmountString,
      formattedProgressAmount,
      groupGifting: translations.groupGifting(),
      physicalGiftLabelComplete: translations.physicalGiftLabelComplete({ requested }),
      completedLabel: completedStrings[randomInteger(0, Object.keys(completedStrings).length - 1)]
    };
  }, [t, product, orders, formatCurrency, t2]);
};

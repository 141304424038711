import React from 'react';

import { useTranslation } from '@shared/core';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { Box, ButtonV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { ShippingAddressOptions } from '@apps/registry/common/components/ShoppingCart';
import { CartFooterSubmitButton, ShippingAddressAtom, WarningAddressAtom } from '@apps/registry/common/components/ShoppingCart/components';

import { AddressForm } from './AddressForm';
import { AddressFormFields } from './types';
import { AddressUsedEnum } from '@apps/registry/common/components/AddressDialog/types';
import { StandardShippingOption } from '../../../components/AddressAtoms/StandardShipping';
import { FormikProps } from 'formik';
import { ShippingAddressFragment } from '@graphql/generated';
import { ShippingFormFields } from './ShippingStep.controller';

export interface ShippingStepProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  formFields: ShippingFormFields;
  formik: FormikProps<AddressFormFields>;
  coupleAddress: Maybe<ShippingAddressFragment>;
  shippingFee: Maybe<string>;
  shippingOptionSelected: ShippingAddressOptions;
  setShippingOptionSelected: (value: ShippingAddressOptions) => void;
  setSuggestedAddress: (address: AddressFormFields | null) => void;
  setAddressUsed: (addressUsed: AddressUsedEnum) => void;
  updateShippingOption: (option: ShippingAddressOptions) => void;
  shippingAddressFormValues: AddressFormFields;
  customShippingAddress?: ShippingAddressFragment | null;
  saveAddress: () => Promise<unknown>;
  disableNextStep?: boolean;
}

export const ShippingStep: React.FC<ShippingStepProps> = ({
  disableNextStep,
  isFormOpen,
  setIsFormOpen,
  formFields,
  formik,
  coupleAddress,
  shippingFee,
  shippingOptionSelected,
  setShippingOptionSelected,
  setSuggestedAddress,
  setAddressUsed,
  updateShippingOption,
  shippingAddressFormValues,
  customShippingAddress,
  saveAddress
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  const { t2 } = useTranslation('guestRegistry');
  const { shippingMethod: shippingMethodLabel, freeShipping, shipTo, giftEmoji, onlyUSAddress, shipToMe, weWillTellAboutPurchase, standardShipping } = t2(
    'shoppingCart',
    'shippingAddressStep'
  );
  const { editButton } = t2('shoppingCart', 'shippingAddressStep', 'addressForm');

  const createAddressLabel = (address: string) => {
    return `${giftEmoji} ${address}`;
  };

  return (
    <SpacingStack spacing={6} width="100%">
      <div>
        <TextV2 typographyVariant="label3" marginBottom={3}>
          {shippingMethodLabel}
        </TextV2>
        <StandardShippingOption label={shippingFee === '0.00' ? freeShipping : standardShipping} value={shippingFee ? '$' + shippingFee : ''} />
      </div>
      <div>
        <TextV2 typographyVariant="label3" marginBottom={3}>
          {shipTo}
        </TextV2>
        <SpacingStack spacing={3} width="100%">
          {!coupleAddress?.name ? (
            <>
              <WarningAddressAtom />
            </>
          ) : (
            <ShippingAddressAtom
              isSelected={shippingOptionSelected === ShippingAddressOptions.CoupleAddress}
              label={createAddressLabel(coupleAddress.name)}
              note={`${coupleAddress.city}, ${coupleAddress.state}, ${coupleAddress.postalCode}`}
              onSelect={() => {
                updateShippingOption(ShippingAddressOptions.CoupleAddress);
                setShippingOptionSelected(ShippingAddressOptions.CoupleAddress);
                setIsFormOpen(false);
              }}
            />
          )}
          <ShippingAddressAtom
            isSelected={shippingOptionSelected === ShippingAddressOptions.ShipToMe}
            label={createAddressLabel(shipToMe)}
            note={weWillTellAboutPurchase}
            tooltip={onlyUSAddress}
            onSelect={() => {
              setShippingOptionSelected(ShippingAddressOptions.ShipToMe);
              if (customShippingAddress?.name) {
                updateShippingOption(ShippingAddressOptions.ShipToMe);
              } else {
                setIsFormOpen(true);
              }
            }}
            address={shippingAddressFormValues?.name ? shippingAddressFormValues : undefined}
          />
        </SpacingStack>
        {isFormOpen ? (
          <Box marginTop={6}>
            <AddressForm
              handleCancelClick={() => {
                setIsFormOpen(false);
                setShippingOptionSelected(ShippingAddressOptions.CoupleAddress);
              }}
              handleSaveClick={() => {
                setIsFormOpen(false);
                saveAddress();
              }}
              formFields={formFields}
              setSuggestedAddress={setSuggestedAddress}
              setAddressUsed={setAddressUsed}
              formik={formik}
            />
          </Box>
        ) : (
          !!shippingAddressFormValues?.name && (
            <ButtonV2 marginTop={6} intent="neutral" variant="outline" onClick={() => setIsFormOpen(true)} fullWidth>
              {editButton}
            </ButtonV2>
          )
        )}
        {!isMobileOrTablet && !isFormOpen && <CartFooterSubmitButton disabled={disableNextStep} />}
      </div>
    </SpacingStack>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CookedProduct, isCustomDonationFundItem } from '@apps/registry/common/selectors/ProductListSelector';
import { DialogV2, LinkV2 } from '@withjoy/joykit';
import { ToastVariableProps } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { useCheckoutMechanisms } from '@apps/registry/common/utils/checkoutMechanisms';
import { useFeatureValue } from '@shared/core/featureFlags';
import { AffiliateProductDialogBody, DropshipProductDetails, DropshipProductDialogBody, LayoutShell } from './components';
import { SkeletonGroup, SkeletonText } from '@shared/components/Skeleton';
import { SkeletonImage, styles } from './components/LayoutShell/LayoutShell.styles';
import { usePurchaseConfirmationProvider } from '../../../Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.provider';
import { useGetPdpDataByProductIdQuery } from '@apps/registry/common/components/Catalog';
import { FormStep, usePurchaseDialogController } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/PurchaseDialog.controller';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { ShippingAddressFragment } from '@graphql/aliases';
import { usePurchaseDialogTranslations } from './hooks';
import { useCheckoutDialogContext } from '@apps/registry/guest/components/CheckoutDialog';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useGetCatalogItemId } from '@apps/registry/common/components/Catalog/hooks/productPdp';

const splitPuchaseDialogOverrides = {
  Content: { props: { overflowX: 'hidden', minHeight: '100%' } },
  Body: { props: { width: '100%', paddingX: { _: 0, sm2: 8 }, paddingTop: { _: 0, sm2: 8 }, position: { _: 'absolute', sm2: 'initial' } } },
  Header: { props: { backgroundColor: 'transparent', zIndex: 5, position: { _: 'unset', sm2: 'sticky' } } },
  CloseButton: { props: { top: { _: 0, sm2: 5 }, right: { _: 0, sm2: 3 } } }
};

export interface PurchaseDialogProps
  extends Readonly<{
    eventHandle: string;
    isDialogOpen: boolean;
    closeDialog: () => void;
    openDialog?: () => void;
    product: CookedProduct;
    eventId?: string;
    eventInfoState?: string;
    eventPhoto?: string;
    openToast: (toast: ToastVariableProps) => void;
    mySessionEmail: Maybe<string>;
    shippingAddress: ShippingAddressFragment | null;
    msrpTranslations: { msrpTitle: string; msrpDescription: string };
  }> {}

export const PurchaseDialog: React.FC<PurchaseDialogProps> = ({
  isDialogOpen,
  closeDialog: defaultCloseDialog,
  product,
  eventId,
  eventInfoState,
  openToast,
  mySessionEmail,
  shippingAddress,
  msrpTranslations,
  ...restProps
}) => {
  const enableSplitPurchaseDialog = useFeatureValue('enableSplitPurchaseDialog');
  const enableAffiliateCheckoutV2 = useFeatureValue('enableAffiliateCheckoutV2');

  const [formStep, setFormStep] = useState<FormStep>(FormStep.Product);
  const [disableInteraction, setDisableInteraction] = useState(false);

  const { isDropshipable, isOutOfStock, isLoading: isLoadingCheckoutMechanisms, checkoutMechanismsWithAltAddress, hideValueProps } = useCheckoutMechanisms({
    productId: product.id
  });
  const { updateDataProvider } = useGuestRegistryState();

  const catalogItemId = useGetCatalogItemId(product);

  const { product: pdpProduct, loading: loadingPdpProduct } = useGetPdpDataByProductIdQuery({
    catalogItemId
  });

  const isLoading = (isLoadingCheckoutMechanisms || loadingPdpProduct) && formStep !== FormStep.AddOtherAddress;

  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });

  const { setIsOutOfStock } = usePurchaseConfirmationProvider();
  const { openCheckoutDialog } = useCheckoutDialogContext();

  // useGetPdpDataByProductIdQuery skips the new request and does not return null/undefined if catalogItemId is not provided
  // this check was added to prevent passing the previous pdpProduct if the new item is not from catalog
  const pdpProductProp = catalogItemId ? pdpProduct : undefined;

  const closeDialog = useCallback(() => {
    setFormStep(FormStep.Product);
    defaultCloseDialog();
  }, [setFormStep, defaultCloseDialog]);

  const shouldOpenNewAffiliateCheckoutDialog =
    !isLoading && isDialogOpen && !isOutOfStock && !isCustomDonationFundItem(product) && !isDropshipable && formStep !== FormStep.AddOtherAddress;

  const { isPurchasable, goBackToRegistry, isPurchaseError, isNotAnExperimentStep } = usePurchaseDialogController({
    closeDialog,
    product,
    itemId: product?.registryItemId || '',
    eventId,
    eventInfoState,
    openToast,
    shouldRequireName: true,
    shouldRequireEmail: !mySessionEmail,
    isDropshipable,
    isOutOfStock,
    checkoutMechanismsWithAltAddress,
    formStep,
    setFormStep
  });

  // If a product has catalogItemId, and it's out of stock - we should show values section
  const canRenderProductValues =
    Boolean(((enableSplitPurchaseDialog && isDropshipable) || (isOutOfStock && catalogItemId)) && formStep === FormStep.Product && !product.externallyOwned) && !hideValueProps;

  useEffect(() => {
    setIsOutOfStock(isOutOfStock);
  }, [isOutOfStock, setIsOutOfStock]);

  if (enableAffiliateCheckoutV2 && shouldOpenNewAffiliateCheckoutDialog) {
    updateDataProvider({ shippingAddress, productId: product.id });
    openCheckoutDialog({ registryItemId: product.registryItemId, isAffiliate: !product.isGroupGiftingEnabled, isGroupGiftingEnabled: !!product.isGroupGiftingEnabled });
    defaultCloseDialog();
  }

  const { otherGifts, purchaseErrorMsg, isNotPurchasableText } = usePurchaseDialogTranslations();
  const purchaseErrorContent = useMemo(() => {
    if (!isPurchasable) {
      return isNotPurchasableText({
        link: () => (
          <LinkV2 typographyVariant="label2" onClick={goBackToRegistry}>
            {otherGifts()}
          </LinkV2>
        )
      });
    }
    if (isPurchaseError) {
      return purchaseErrorMsg();
    }
    return null;
  }, [isPurchasable, isNotPurchasableText, isPurchaseError, purchaseErrorMsg, goBackToRegistry, otherGifts]);

  return (
    <DialogV2
      id="PurchaseDialog"
      size="3xl"
      onClose={disableInteraction ? undefined : closeDialog}
      isOpen={isDialogOpen}
      overrides={enableSplitPurchaseDialog && isDropshipable ? splitPuchaseDialogOverrides : undefined}
    >
      <DialogV2.Header>
        <DialogV2.CloseButton disabled={disableInteraction} color="mono14" _hover={{ color: 'black' }} />
      </DialogV2.Header>
      <DialogV2.Body>
        <SkeletonGroup
          isReady={!isLoading}
          placeholder={
            <LayoutShell>
              <LayoutShell.MediaPanel>
                <SkeletonImage __css={styles.skeletonImage} />
              </LayoutShell.MediaPanel>
              <LayoutShell.ContentPanel>
                <SkeletonText marginBottom={5} width="100%" skeletonHeight="31px" rows={1} />
                <SkeletonText marginBottom={6} width="100%" skeletonHeight="48px" rows={1} />
                <SkeletonText marginBottom={6} width="100%" skeletonHeight="48px" rows={1} />
                <SkeletonText marginBottom={6} width="100%" skeletonHeight="48px" rows={1} />
                <SkeletonText marginBottom={7} width="100%" skeletonHeight="100px" rows={1} />
                <SkeletonText marginBottom={7} width="100%" skeletonHeight="48px" rows={1} />
              </LayoutShell.ContentPanel>
            </LayoutShell>
          }
        >
          {formStep === FormStep.AddOtherAddress || (enableSplitPurchaseDialog && isDropshipable) || product.isGroupGiftingEnabled ? (
            <>
              <DropshipProductDialogBody
                product={product}
                pdpProduct={pdpProductProp}
                closeDialog={closeDialog}
                isOutOfStock={isOutOfStock}
                isDropshipable
                eventId={eventId}
                eventInfoState={eventInfoState}
                openToast={openToast}
                mySessionEmail={mySessionEmail}
                shippingAddress={shippingAddress}
                purchaseErrorContent={purchaseErrorContent}
                checkoutMechanismsWithAltAddress={checkoutMechanismsWithAltAddress}
                formStep={formStep}
                setFormStep={setFormStep}
                setDisableInteraction={setDisableInteraction}
                canRenderProductValues={canRenderProductValues}
                msrpTranslations={msrpTranslations}
                {...restProps}
              />
              {isMobile && isNotAnExperimentStep && (
                <DropshipProductDetails
                  product={product}
                  pdpProductProp={pdpProductProp}
                  eventPhoto={restProps.eventPhoto}
                  isLoading={isLoading}
                  canRenderProductValues={canRenderProductValues}
                />
              )}
            </>
          ) : (
            <>
              <AffiliateProductDialogBody
                product={product}
                pdpProduct={pdpProductProp}
                closeDialog={closeDialog}
                isOutOfStock={isOutOfStock}
                isDropshipable={isDropshipable}
                eventId={eventId}
                eventInfoState={eventInfoState}
                openToast={openToast}
                mySessionEmail={mySessionEmail}
                purchaseErrorContent={purchaseErrorContent}
                checkoutMechanismsWithAltAddress={checkoutMechanismsWithAltAddress}
                formStep={formStep}
                setFormStep={setFormStep}
                msrpTranslations={msrpTranslations}
                {...restProps}
              />
              {isMobile && (
                <DropshipProductDetails
                  product={product}
                  pdpProductProp={pdpProductProp}
                  eventPhoto={restProps.eventPhoto}
                  isLoading={isLoading}
                  canRenderProductValues={canRenderProductValues}
                />
              )}
            </>
          )}
        </SkeletonGroup>
      </DialogV2.Body>
      {!isMobile && isNotAnExperimentStep && (
        <DropshipProductDetails
          product={product}
          pdpProductProp={pdpProductProp}
          eventPhoto={restProps.eventPhoto}
          isLoading={isLoading}
          canRenderProductValues={canRenderProductValues}
        />
      )}
    </DialogV2>
  );
};

export default PurchaseDialog;

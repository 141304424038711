import { Box, styled } from '@withjoy/joykit';
import { wiggleAnimation } from './envelopeAnimation';

const SHADOW = '1px 10px 16px rgba(0,0,0,0.7)';
const SHADOW_OPEN = '1px 10px 90px rgba(0,0,0,0.9)';

export const PAPER_TEXTURE_URL = 'https://withjoy.com/media/raw/print/paper_texture_1200.png';

export const WrapperContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
`;

export const EnvelopeContainer = styled.div<{ isOpen: boolean; height: number; verticalOffset: number; hasHover: boolean; flapOpen: boolean }>`
  position: relative;
  transition: all 1s;
  animation-name: ${wiggleAnimation};
  animation-duration: 2s;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  animation-iteration-count: ${({ isOpen, hasHover, flapOpen }) => (isOpen || hasHover || flapOpen ? 0 : 'infinite')};
  margin-top: ${({ isOpen, flapOpen, height, verticalOffset, hasHover }) =>
    isOpen ? `${height + 64}px` : hasHover || flapOpen ? `${verticalOffset ? verticalOffset - 5 : 125}px` : `${verticalOffset ? verticalOffset : 100}px`};
`;

export const EnvelopePositioner = styled(Box)<{ backgroundColor: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;
`;

export const InputFlap = styled.input`
  display: none;
`;

export const EnvelopeBack = styled.div<{ backgroundColor: string; hasHover: boolean; isOpen: boolean; width: number; height: number }>`
  position: relative;
  transition: all 0.2s;
  background: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  width: ${({ hasHover, isOpen, width }) => (hasHover && !isOpen ? `${width - 2}px` : `${width}px`)};
  height: ${({ hasHover, isOpen, height }) => (hasHover && !isOpen ? `${height + 2}px` : `${height}px`)};
`;

export const CardWrapper = styled.div<{ isOpen: boolean; height: number; hasHover: boolean; envelopeHeight: number }>`
  position: absolute;
  top: ${({ isOpen, height, hasHover }) => (isOpen ? `-${height + 60}` : hasHover ? '-2' : '3')}px;
  margin: 5px;
  padding-top: ${({ isOpen }) => (isOpen ? '36px' : '0px')};
  display: flex;
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  align-items: center;
  justify-content: center;
  transition: all 1s;
  z-index: 1;
  clip-path: inset(0% -100% ${({ isOpen, envelopeHeight }) => (isOpen ? '-20%' : `calc(100% - ${envelopeHeight - 20}px)`)} -100%);
`;
//   clip-path: polygon(0% 0%, 0% 4%, 50% 56%, 100% 4%, 100% 0%);
export const CardFlap = styled.div<{ isOpen: boolean; closedColor: string; openColor: string; hoverColor: string; hasHover: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ isOpen, openColor, closedColor, hasHover, hoverColor }) => (isOpen ? openColor : hasHover ? hoverColor : closedColor)};
  top: 0;
  left: 0;
  clip-path: polygon(0% 0%, 0% 4%, 5% 40%, 95% 40%, 100% 4%, 100% 0%);
  transform-origin: top;
  z-index: ${({ isOpen }) => (isOpen ? 0 : 400)};
  transform: rotateX(${({ isOpen, hasHover }) => (isOpen ? '180deg' : hasHover ? '120deg' : 0)});
  transition: all 0.4s;
  cursor: pointer;
`;

export const CardFlapTexture = styled.div<{ isOpen: boolean; hasHover: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  clip-path: polygon(0% 0%, 0% 4%, 5% 40%, 95% 40%, 100% 4%, 100% 0%);
  transform-origin: top;
  z-index: ${({ isOpen }) => (isOpen ? 1 : 401)};
  transform: rotateX(${({ isOpen, hasHover }) => (isOpen ? '180deg' : hasHover ? '120deg' : 0)});
  transition: all 0.4s;
  cursor: pointer;
  background-image: url('${PAPER_TEXTURE_URL}');
  opacity: 0.4;
`;

export const FlapWraper = styled.div<{ isOpen: boolean; hasHover: boolean }>`
  filter: drop-shadow(${({ isOpen }) => (isOpen ? SHADOW_OPEN : SHADOW)});
`;

export const CardLeft = styled.div<{ sideColor: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 200;
  background-color: ${({ sideColor }) => sideColor};
  clip-path: polygon(0% 1%, 8% 3%, 8% 100%, 0% 100%);
  cursor: pointer;
`;

export const CardRight = styled.div<{ sideColor: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 200;
  background-color: ${({ sideColor }) => sideColor};
  clip-path: polygon(100% 1%, 92% 3%, 92% 100%, 100% 100%);
  cursor: pointer;
`;

export const CardBottom = styled.div<{ bottomColor: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 300;
  background-color: ${({ bottomColor }) => bottomColor};
  clip-path: polygon(0% 100%, 2% 95%, 2% 15%, 98% 15%, 98% 95%, 100% 100%);
  cursor: pointer;
`;
export const CardBottomTexture = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 301;
  clip-path: polygon(0% 100%, 2% 95%, 2% 15%, 98% 15%, 98% 95%, 100% 100%);
  cursor: pointer;
  background-image: url('${PAPER_TEXTURE_URL}');
  opacity: 0.4;
`;

export const CardShadow = styled.div<{ isOpen: boolean; hasHover: boolean }>`
  transition: all 0.3s;
  width: ${({ hasHover }) => (hasHover ? '100%' : 'calc(100% - 30px)')};
  height: ${({ hasHover }) => (hasHover ? '13px;' : '12px')};
  border-radius: 50%;
  background-color: ${({ hasHover }) => (hasHover ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0.12)')};
  position: absolute;
  z-index: 300;
  left: ${({ hasHover }) => (hasHover ? '0' : '10px')};
  bottom: ${({ hasHover }) => (hasHover ? '-58px' : '-54px')};
  filter: ${({ hasHover }) => (hasHover ? 'blur(1.6px)' : 'blur(1.5px)')};
`;

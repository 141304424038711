import { useContext } from 'react';
import { ShoppingCartContext } from '.';

export const useShoppingCart = () => {
  const context = useContext(ShoppingCartContext);
  if (context === undefined) {
    throw new Error('useShoppingCart must be used with a ShoppingCartContext');
  }
  return context;
};

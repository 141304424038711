import React, { useEffect } from 'react';
import { ProductListContainer } from '../ProductList';
import { GuestRegistryState } from '../../../../GuestRegistry.controller';
import '@assets/charm.css';
import { ShoppingCartV2 } from '../../../../../common/components/ShoppingCart';
import { StoredGiftReservationFieldsProvider } from '../StoredGiftReservationFieldsProvider';
import { useGuestRegistryController } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { CheckoutDialogProvider } from '../../../../components/CheckoutDialog';
import { Box } from '@withjoy/joykit';
import { useEventTypeConfig } from '@shared/utils/eventType';
import { SlapAdsRegistry } from '@apps/guest/packages/layout-engine/components/SlapAdsRegistry';
import { useGetEventRegistriesAndOrdersQuery } from '@graphql/generated';
import { getTotalRegistryItemsCount } from '@apps/registry/common/utils/totalRegistryItemsCount';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { OrderTrackingProvider } from '@apps/registry/guest/providers';
import { ProductListViewSkeleton } from '../ProductList/ProductListViewSkeleton';
import i18n from '@shared/core/i18n/i18n';

type Props = { eventHandle: string; registryState: GuestRegistryState; eventId: string; showToast: () => void; isShowCartSection: boolean };

export const GuestShopping: React.FC<Props> = ({ eventHandle, registryState, eventId, showToast, isShowCartSection }) => {
  const { openToast } = useGuestRegistryController();
  const { config } = useEventTypeConfig();
  const { updateOrdersAndRegistry } = useGuestRegistryState();

  const { eventPhoto, isAdmin, registryCurrencyCode, shippingAddress } = registryState;

  // Preloads translation namepspaces to avoid flickering when `GetEventRegistriesAndOrders`
  // query is completed and `ProductListContainer` is rendered
  useEffect(() => {
    i18n.loadNamespaces(['guestRegistry', 'yourRegistry', 'generalFormValidation', 'sharedRegistry']);
  }, []);

  const { data, loading } = useGetEventRegistriesAndOrdersQuery({
    batchMode: 'fast',
    variables: { eventHandle },
    onCompleted: data => {
      const orders = data?.eventByName?.registry.orders;
      const registry = data?.eventByName?.info.registry;
      if (orders || registry) {
        updateOrdersAndRegistry(orders, registry);
      }
    }
  });

  if (loading) {
    return <ProductListViewSkeleton />;
  }

  const registry = data?.eventByName?.info.registry;
  const orders = data?.eventByName?.registry.orders;

  const giftCount = registry?.reduce((acc, curr) => {
    return getTotalRegistryItemsCount(curr?.items) + acc;
  }, 0);

  return (
    <OrderTrackingProvider registries={registry}>
      <StoredGiftReservationFieldsProvider sessionEmail={registry?.filter(registry => registry.id === eventId)[0]?.mySession?.email}>
        <CheckoutDialogProvider eventId={eventId} registryState={registryState} isAdmin={isAdmin} onReserveGift={showToast} openToast={openToast}>
          <ProductListContainer
            eventHandle={eventHandle}
            registries={registry}
            orders={orders}
            eventId={eventId}
            eventPhoto={eventPhoto}
            showToast={showToast}
            isShowCartSection={isShowCartSection}
            openToast={openToast}
            shippingAddress={shippingAddress}
          />
          {isAdmin && giftCount === 0 && (
            <Box paddingX={[5, null]} margin={'0 auto'} marginY={5} maxWidth={[null, null, 440]}>
              <SlapAdsRegistry redirectTo={`/${eventHandle}/edit/registry${config?.registryAdminShopLink}`} />
            </Box>
          )}
          <ShoppingCartV2
            registry={registry}
            shippingAddress={shippingAddress}
            orders={orders}
            eventId={eventId || ''}
            registryCurrencyCode={registryCurrencyCode}
            isShowCartSection={isShowCartSection}
            openToast={openToast}
            eventHandle={eventHandle}
            registryState={registryState}
          />
        </CheckoutDialogProvider>
      </StoredGiftReservationFieldsProvider>
    </OrderTrackingProvider>
  );
};

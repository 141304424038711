import { CartCheckoutSteps, CartSections, HistoryItem, LineItem, ProductListV2, ShippingAddressOptions, ShippingMethods } from '../ShoppingCart.types';
import { State, DerivedState, HistoryState } from './ShoppingCart.provider';
import { GetEventRegistriesAndOrdersQuery, GetItemCheckoutMechanismsQuery, RegistryOrderFragment, ShippingAddressFragment, ShippingAddressInput } from '@graphql/generated';
import { ApolloQueryResult } from '@apollo/client';
import { isDropshippable } from '@apps/registry/common/utils/checkoutMechanisms';
import { getLocalStorage } from '@shared/core';

const localStorage = getLocalStorage();

export const NATIONAL_COUNTRY_CODE = 'USA';
export const NATIONAL_COUNTRY = 'United States';

export const STATE_INITIAL: State = {
  itemList: [],
  lastUpdated: null,
  hadOnlyStillNeededItems: true
};

export const HISTORY_STATE_INITIAL: HistoryState = {
  historyItemList: []
};

export const DERIVED_STATE_INITIAL: DerivedState = {
  itemListCount: 0,

  // Section & Step
  checkoutStepsFlow: [],
  currentSection: CartSections.CART,
  currentCheckoutStep: CartCheckoutSteps.Cart,

  // Shipping Step
  shippingMethod: ShippingMethods.FreeByJoy,
  selectedShippingOption: ShippingAddressOptions.CoupleAddress,

  // Details Step
  details: { message: '', name: '', email: '' },

  // General Cart
  isShoppingCartOpen: false,
  shippingFeeInUnits: 0,
  verificationStatus: 'idle'
};

export const getInitialState = (shoppingCartKey: string) => {
  const storageDataString = localStorage.getItem(shoppingCartKey);

  if (!storageDataString) {
    return STATE_INITIAL;
  }

  try {
    const storageData = JSON.parse(storageDataString);
    return storageData;
  } catch (err) {
    return STATE_INITIAL;
  }
};

export const getInitialHistoryState = (shoppingCartHistoryKey: string) => {
  const storageDataString = localStorage.getItem(shoppingCartHistoryKey);

  if (!storageDataString) {
    return HISTORY_STATE_INITIAL;
  }

  try {
    const storageData = JSON.parse(storageDataString);
    return storageData;
  } catch (err) {
    return HISTORY_STATE_INITIAL;
  }
};

export const getStillNeeded = (prevState: State, data: GetEventRegistriesAndOrdersQuery) => {
  const registry = data.eventByName?.info.registry;

  if (registry) {
    // Get all registry products into a flat list
    const productsStillNeeded = registry.reduce((acc: Array<{ stillNeeded: number; id: string }>, registryEntry) => {
      if (registryEntry?.items) return [...acc, ...registryEntry.items.map(item => ({ stillNeeded: item.stillNeeded, id: item.id }))];
      else return acc;
    }, []);

    // Check every item from state to be in the product list
    const newState = {
      ...prevState,
      itemList: prevState.itemList.filter(item => {
        const itemStillNeeded = productsStillNeeded.find(
          (product: { stillNeeded: number; id: string }) => product.stillNeeded > 0 && product.stillNeeded >= item.quantity && product.id === item.registryItemId
        );
        return !!itemStillNeeded;
      })
    };

    newState.hadOnlyStillNeededItems = newState.itemList.length === prevState.itemList.length;

    return newState;
  } else {
    return prevState;
  }
};

export const getInStock = (prevState: State, data: Array<ApolloQueryResult<GetItemCheckoutMechanismsQuery>>) => {
  const productsInStock = new Set(data.filter(i => isDropshippable(i.data?.registryItem)).map(i => i.data.registryItem?.id));

  // Check every item from state to be in the product list
  const newState = {
    ...prevState,
    itemList: prevState.itemList.map(item => {
      return {
        ...item,
        inStock: productsInStock.has(item.registryItemId)
      };
    })
  };

  return newState;
};

export const getUpdatedStateByRemoveItem = (prevState: State, registryItemId: string) => {
  const itemList = prevState.itemList.filter(item => registryItemId !== item.registryItemId);

  const newState = {
    ...prevState,
    itemList,
    lastUpdated: Date.now()
  };

  return newState;
};

export const getUpdatedStateByAddItem = (prevState: State, data: LineItem, maxQuantity: number) => {
  let newState;
  const index = prevState.itemList.findIndex(item => data.registryItemId === item.registryItemId);

  if (index === -1) {
    newState = {
      ...prevState,
      itemList: [...prevState.itemList, { ...data, inStock: true }],
      lastUpdated: Date.now()
    };
  } else {
    const item = prevState.itemList[index];
    const quantity = Math.min(item.quantity + data.quantity, maxQuantity);

    newState = getUpdatedStateByUpdateItem(prevState, { ...item, quantity });
  }

  return newState;
};

export const getUpdatedStateByUpdateItem = (prevState: State, data: LineItem) => {
  const index = prevState.itemList.findIndex(item => data.registryItemId === item.registryItemId);

  if (index === -1) {
    return prevState;
  }

  const itemList = [...prevState.itemList];
  itemList[index] = data;
  const newState = {
    ...prevState,
    itemList,
    lastUpdated: Date.now()
  };

  return newState;
};

export const formatAddress = (shippingAddress: ShippingAddressFragment): ShippingAddressInput => ({
  address1: shippingAddress.address1,
  address2: shippingAddress.address2,
  city: shippingAddress.city,
  country: shippingAddress.country,
  countryCode: shippingAddress.countryCode,
  name: shippingAddress.name,
  postalCode: shippingAddress.postalCode,
  state: shippingAddress.state,
  validated: shippingAddress.validated
});

export const getMarkedAsVisited = (prevState: HistoryState, reservedOrderList: RegistryOrderFragment[], productList: ProductListV2) => {
  const historyItemList = reservedOrderList.reduce((acc, order) => {
    const registryItemId = order?.lineItems?.filter(lineItem => lineItem.registryItem)[0].registryItem?.id;
    const product = productList.products.find(product => product.id === registryItemId);
    if (product) {
      const visited = prevState.historyItemList.find(item => item.registryItemId === product.registryItemId && order.id === item.orderId);
      if (!visited) return [...acc, { registryItemId: product.registryItemId, orderId: order.id }];
    }

    return acc;
  }, prevState.historyItemList as Array<HistoryItem>);

  return { ...prevState, historyItemList };
};

import React from 'react';
import { Box, Flex, TextV2, StackProps, SpacingStack, TooltipV2, IconV2 } from '@withjoy/joykit';
import {
  isProductFullyPurchased,
  ItemWithPrice,
  contributedPercentage,
  isGroupGiftPurchasedAsPhysical,
  isGroupGiftReservedAsPhysical
} from '@apps/registry/common/selectors/ProductListSelector';
import { styles, StyledGiftTrackerContainer, StyledProgressBar, StyledLabel } from './RegistryItemProgressTracker.styles';
import { useProgressTrackerTranslations } from './RegistryItemProgressTracker.i18n';
import { RegistryOrderList } from '@apps/registry/common/state/RegistryProducts';
import { pxToRem } from '@withjoy/joykit/theme';
import { TooltipIcon } from '@apps/registry/admin/components/EditGift/EditGiftDialog.styles';
import { useTranslation } from '@shared/core';
import { Help } from '@assets/icons';

type CashFundProgressTrackerProps = Readonly<{
  product: ItemWithPrice;
  orders: RegistryOrderList;
  hideProgressTextIfNotStarted?: boolean;
  hideProgressText?: boolean;
  viewContext: 'admin' | 'guest';
  isShowCartSection?: boolean;
  isGroupGifting?: boolean;
  isProductRowView?: boolean;
  currentDonation?: number;
}>;

export const RegistryItemProgressTracker = ({
  product,
  orders,
  hideProgressText,
  viewContext,
  isShowCartSection,
  isGroupGifting,
  isProductRowView,
  currentDonation,
  ...restProps
}: CashFundProgressTrackerProps & Omit<StackProps, 'children'>) => {
  const { reserved, requested, donationFund, purchased } = product;
  const translations = useProgressTrackerTranslations(product, orders);
  const isCompleted = isProductFullyPurchased(product);
  const contributedAmount = contributedPercentage(product);
  const isGuestSite = viewContext === 'guest';

  const progressDecimal = donationFund ? donationFund.received / donationFund.goal : (reserved + purchased) / requested;
  const progressWithCurrentDonation = donationFund && currentDonation ? ((donationFund?.received + currentDonation) / donationFund.goal) * 100 : 0;

  const { t2 } = useTranslation('sharedRegistry');
  const { groupGiftingTooltip } = t2('giftDialog');

  if (isGroupGifting) {
    if (isGroupGiftReservedAsPhysical(product)) {
      return null;
    }

    return (
      <SpacingStack spacing={3}>
        {isGroupGiftPurchasedAsPhysical(product) ? (
          <StyledLabel {...styles.label}>{translations.physicalGiftLabelComplete}</StyledLabel>
        ) : (
          <Flex justifyContent="space-between">
            <Flex>
              <TextV2 typographyVariant="label3">{translations.groupGifting}</TextV2>
              {viewContext === 'admin' && !isProductRowView && (
                <TooltipV2 placement="topStart" content={<Box width="480px">{groupGiftingTooltip}</Box>}>
                  <TooltipIcon>
                    <IconV2 size="sm" color="mono8" marginLeft={3}>
                      <Help />
                    </IconV2>
                  </TooltipIcon>
                </TooltipV2>
              )}
            </Flex>
            <TextV2 typographyVariant="label2" color="mono10">
              {translations.groupGiftingProgressText}
            </TextV2>
          </Flex>
        )}
        <StyledGiftTrackerContainer
          $progressDecimal={isGroupGiftPurchasedAsPhysical(product) ? 100 : progressDecimal}
          $isGuestSite={isGuestSite}
          vertical
          spacing={3}
          {...restProps}
        >
          <StyledProgressBar isGroupGiftingVariant={!isProductRowView}>
            {!!progressWithCurrentDonation && (
              <Box height={pxToRem(16)} borderRadius={pxToRem(8)} width={`${progressWithCurrentDonation < 100 ? progressWithCurrentDonation : 100}%`} backgroundColor="#189289" />
            )}
          </StyledProgressBar>
        </StyledGiftTrackerContainer>
        {isGroupGiftPurchasedAsPhysical(product) && <StyledLabel {...styles.label}>{translations.completedLabel}</StyledLabel>}
      </SpacingStack>
    );
  }

  const renderCalculatedDetails = () => {
    if (isGuestSite) {
      if (isCompleted) {
        return <StyledLabel {...styles.label}>{translations.titleText}</StyledLabel>;
      } else {
        return (
          <>
            <StyledLabel {...styles.label} width="50%">
              {translations.contributionText}
            </StyledLabel>
            <StyledLabel {...styles.label}>{translations.goalText}</StyledLabel>
          </>
        );
      }
    } else {
      if (isCompleted) {
        return <StyledLabel {...styles.label}>{translations.remainingText}</StyledLabel>;
      } else {
        return (
          <>
            {!hideProgressText && <StyledLabel {...styles.label}>{translations.progressText}</StyledLabel>}
            <StyledLabel {...styles.label}>{translations.remainingText}</StyledLabel>
          </>
        );
      }
    }
  };

  return (
    <StyledGiftTrackerContainer $progressDecimal={progressDecimal} $isGuestSite={isGuestSite} vertical={true} spacing={3} {...restProps} marginTop={isGuestSite ? 4 : 3}>
      {!isGuestSite && <Box>{translations.titleText}</Box>}
      <StyledProgressBar width={!!contributedAmount ? (isShowCartSection ? '86%' : '90%') : null} />
      {!!contributedAmount && (
        <Box position="absolute" right="0" top="-13px">
          <TextV2 typographyVariant="label2" color="gray8">
            {contributedAmount}
          </TextV2>
        </Box>
      )}

      <Flex justifyContent="space-between">{renderCalculatedDetails()}</Flex>
    </StyledGiftTrackerContainer>
  );
};

RegistryItemProgressTracker.displayName = 'RegistryItemProgressTracker';

import { useMemo, useCallback, useState } from 'react';
import { RegistryList, RegistryOrderList } from '@apps/registry/common/state/RegistryProducts';
import { calculateNumberPrice, isPricedProduct, useCookedProductList } from '@apps/registry/common/selectors/ProductListSelector';
import { RegistryOrderFragment } from '@graphql/generated';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { useShoppingCart } from './state';
import { InCartProduct } from './ShoppingCart.types';

interface ShoppingCartControllerProps {
  registries: RegistryList;
  orders: RegistryOrderList;
  registryCurrencyCode?: string;
}

export const useShoppingCartController = ({ registries, orders, registryCurrencyCode }: ShoppingCartControllerProps) => {
  const [helpDialogOpen, setHelpDialogOpen] = useState<boolean>(false);
  const productList = useCookedProductList(registries, orders, 'everything');
  const { formatCurrency } = useCurrencyFormatter();
  const {
    state: { itemList }
  } = useShoppingCart();

  const reservedOrderList = useMemo(() => {
    const reservedOrders: Array<RegistryOrderFragment> = [];
    registries?.forEach(registry => {
      if (registry && registry.myReservedOrders.length > 0) {
        registry.myReservedOrders.forEach(order => {
          if (order) {
            reservedOrders.push(order);
          }
        });
      }
    });
    return reservedOrders;
  }, [registries]);

  const purchasedOrderList = useMemo(() => {
    const purchasedOrders: Array<RegistryOrderFragment> = [];
    registries?.forEach(registry => {
      if (registry && registry.myPurchasedOrders.length > 0) {
        registry.myPurchasedOrders.forEach(order => {
          if (order) {
            purchasedOrders.push(order);
          }
        });
      }
    });
    return purchasedOrders;
  }, [registries]);

  const orderCount = useMemo(() => {
    return reservedOrderList?.length || 0;
  }, [reservedOrderList]);

  const cartOrderCount = useMemo(() => {
    const reservedOrdersCount = reservedOrderList?.length || 0;
    const purchasedOrdersCount = purchasedOrderList?.length || 0;
    return reservedOrdersCount + purchasedOrdersCount;
  }, [reservedOrderList, purchasedOrderList]);

  const reservedOrderIds = useMemo(() => {
    return reservedOrderList
      ? reservedOrderList.map(reserved => {
          const lineItem = reserved && reserved.lineItems.find(lineItem => lineItem.registryItem);
          return lineItem?.registryItem?.id;
        })
      : [];
  }, [reservedOrderList]);

  const purchasedOrderIds = useMemo(() => {
    return purchasedOrderList
      ? purchasedOrderList.map(reserved => {
          const lineItem = reserved && reserved.lineItems.find(lineItem => lineItem.registryItem);
          return lineItem?.registryItem?.id;
        })
      : [];
  }, [purchasedOrderList]);

  const getReservedOrderByProductId = useCallback(
    (productId: string) => {
      return reservedOrderList.find(reserved => {
        const lineItem = reserved && reserved.lineItems.find(lineItem => lineItem.registryItem);
        return lineItem?.registryItem?.id === productId;
      });
    },
    [reservedOrderList]
  );

  const showHelpDialog = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setHelpDialogOpen(false);
  }, []);

  const cartTotal = useMemo(() => {
    const cartProductList = productList.products?.filter(registry => reservedOrderIds?.some(item => registry.id === item));
    let total = 0;
    cartProductList.map(product => {
      if (product.donationFund) {
        const { donations } = product.donationFund.fund;
        const ss = donations.filter(donation => reservedOrderList?.some(item => donation.id === item?.id));
        ss.map(donation => {
          if (donation) {
            total = total + calculateNumberPrice(donation.amount);
          }
        });
      } else {
        const reserverOrder = getReservedOrderByProductId(product.id);
        const multiplier: number = reserverOrder?.lineItems[0].quantity || 1;
        total = total + (isPricedProduct(product) ? product.numberPrice * multiplier : 0);
      }
    });
    return formatCurrency({ priceFloatingPointDecimalString: `${total}`, priceCurrencyCode: registryCurrencyCode, formatForm: 'explicit' });
  }, [productList.products, formatCurrency, registryCurrencyCode, reservedOrderIds, getReservedOrderByProductId, reservedOrderList]);

  const showShoppingCart = cartOrderCount > 0;

  const inCartProductList = useMemo(
    () =>
      itemList.reduce((acc, item) => {
        const product = productList.products.find(product => product.registryItemId === item.registryItemId);
        if (product) acc.push({ product, ...item });
        return acc;
      }, [] as Array<InCartProduct>),
    [productList, itemList]
  );

  const inCartProductCount = useMemo(() => inCartProductList.reduce((total, product) => (product.inStock ? total + product.quantity : total), 0), [inCartProductList]);

  const inCartProductTotal = useMemo(() => {
    return inCartProductList.reduce((total, product) => {
      if (isPricedProduct(product.product) && product.inStock) {
        return total + product?.product?.numberPrice * product?.quantity;
      }
      return total;
    }, 0);
  }, [inCartProductList]);

  return {
    helpDialogOpen,
    productList,
    reservedOrderList,
    purchasedOrderList,
    orderCount,
    cartOrderCount,
    reservedOrderIds,
    purchasedOrderIds,
    cartTotal,
    inCartProductList,
    inCartProductCount,
    inCartProductTotal,
    showShoppingCart,
    getReservedOrderByProductId,
    showHelpDialog,
    handleDialogClose
  };
};

import React, { useMemo } from 'react';
import { TextV2 } from '@withjoy/joykit';
import { isPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';

import { StyledFlex } from './InSummaryItem.styles';
import { ItemBase } from '../..';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { InCartProduct } from '../../../ShoppingCart.types';

type InSummaryItemProps = Readonly<{ inCartProduct: InCartProduct }>;

export const InSummaryItem: React.FC<InSummaryItemProps> = ({ inCartProduct }) => {
  const { formatCurrency } = useCurrencyFormatter();

  const { product, quantity } = inCartProduct;
  const totalPrice = useMemo(() => {
    if (isPricedProduct(product)) {
      const totalCharmPriceString = `${quantity * product.numberPrice}`;

      return formatCurrency({
        priceFloatingPointDecimalString: totalCharmPriceString,
        priceCurrencyCode: product.currencyCode,
        formatForm: 'short'
      });
    }

    return '';
  }, [product, quantity, formatCurrency]);

  const price = useMemo(
    () =>
      isPricedProduct(product)
        ? formatCurrency({
            priceFloatingPointDecimalString: product.floatingPointDecimalString,
            priceCurrencyCode: product.currencyCode,
            formatForm: 'short'
          })
        : '',
    [product, formatCurrency]
  );

  return (
    <ItemBase
      registryItemId={inCartProduct.registryItemId}
      inStock={inCartProduct.inStock}
      product={inCartProduct.product}
      siteName={inCartProduct.product.brand}
      __css={{ border: 'none' }}
      variant="transparent"
    >
      <StyledFlex>
        <TextV2 typographyVariant="body1">{`${inCartProduct.quantity} x ${price}`}</TextV2>
        <TextV2 textAlign="center" typographyVariant="hed1">
          {totalPrice}
        </TextV2>
      </StyledFlex>
    </ItemBase>
  );
};

import React, { useCallback, useEffect, useMemo } from 'react';
import {
  StyledActionButtonsContainer,
  StyledSuccessPageContainer,
  StyledInformationContainer,
  StyledFormContainer,
  BuyForMyselfWrapper,
  StyledItemImageLarge,
  StyledActionButtonsWrapper,
  StyledInformationWrapper,
  styles
} from './DropshipPurchaseConfirmationV2.styles';
import { Box, ButtonV2, Divider, Flex, TextV2 } from '@withjoy/joykit';
import { useDropshipPurchaseConfirmationController } from './DropshipPurchaseConfirmation.controller';
import { useRouterHelper, useTranslation } from '@shared/core';
import { ErrorSplash, useGiftWrapECardPrice } from '@shared/components';
import { TempSpinner } from '@shared/components/TempSpinner';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { RegistryItemFragment, RegistryOrderFragment } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { withWindow } from '@shared/utils/withWindow';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { ResizedLogo } from '../ShoppingCart/ShoppingCartInnerV2/ShoppingCartInnerV2.styles';
import { JoyPurchasedOrder } from '../ShoppingCart/ShoppingCartInnerV2/steps/CartReviewStep/components/ReceiptsSection/components';
import { useEventInfo } from '@shared/utils/eventInfo';
import { CheckOutline } from '@withjoy/joykit/icons';
import { usePurchaseContextIdFromLocation } from '@apps/registry/common/utils/dropship';
import { RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { useRegistryHost } from '../../utils/useRegistryHost';

type SalesOrderItemLine = {
  title: string;
  photos: Array<{ id: string; url: string }>;
  isGroupGiftingEnabled?: boolean;
};

interface DropshipPurchaseConfirmationProps {
  registries: RegistryList;
}

interface BuyForMyselfFromPurchaseConfirmationProps {
  item: SalesOrderItemLine;
  getItemPhoto: (item: SalesOrderItemLine) => string | undefined;
  selectedRegistryItem: RegistryItemFragment;
}

const BuyForMyselfFromPurchaseConfirmation = ({ item, getItemPhoto, selectedRegistryItem }: BuyForMyselfFromPurchaseConfirmationProps) => {
  const { t } = useTranslation('guestRegistry');
  const { likeWhatYouSee, treatYourself, weWontTellAnyone, buyNowBtn } = t('confirmationPage');
  const { buyNowClickedGuest } = useGuestRegistryTelemetry();
  const enableBuyForMyselfFromPurchaseConfirmation = useFeatureValue('enableBuyForMyselfFromPurchaseConfirmation');

  if (!enableBuyForMyselfFromPurchaseConfirmation || !selectedRegistryItem.productData.checkoutUrl) {
    return null;
  }

  const {
    id,
    productData: { checkoutUrl }
  } = selectedRegistryItem;

  const handleBuyNowClick = () => {
    if (checkoutUrl) {
      withWindow(window => {
        window.open(checkoutUrl, '__blank');
      });
      buyNowClickedGuest({ productId: id, checkoutUrl, title: item.title, isGroupGiftingEnabled: item.isGroupGiftingEnabled });
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" marginTop={48} textAlign="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <TextV2 typographyVariant="hed5">{likeWhatYouSee()}</TextV2>
        <TextV2 typographyVariant="hed5">
          {treatYourself()}{' '}
          <span role="img" aria-label="Partying face">
            &#129395;
          </span>
        </TextV2>
      </Box>
      <TextV2 typographyVariant="body1" marginBottom={24} marginTop={4}>
        {weWontTellAnyone()}
      </TextV2>
      <StyledItemImageLarge image={getItemPhoto(item)} />
      <TextV2 typographyVariant="hed1" marginBottom={40} marginTop={12}>
        {item.title}
      </TextV2>
      <ButtonV2 intent="productive" onClick={handleBuyNowClick} fullWidth variant="outline">
        {buyNowBtn()}
      </ButtonV2>
    </Box>
  );
};

const DropshipPurchaseConfirmationV2: React.FC<DropshipPurchaseConfirmationProps> = ({ registries }) => {
  const { data, loading, error, goBackToRegistry } = useDropshipPurchaseConfirmationController();
  const { formatCurrency } = useCurrencyFormatter();
  const { isGuestRoute } = useRouterHelper();
  const { eventInfo } = useEventInfo();
  const eventFirebaseId = eventInfo?.eventFirebaseId;
  const purchaseContextId = usePurchaseContextIdFromLocation();
  const host = useRegistryHost();
  const registryGiftWrapEcardPrice = useGiftWrapECardPrice();

  const order = useMemo(() => {
    if (!registries) {
      return null;
    }

    return registries?.reduce((acc: RegistryOrderFragment | null, registry) => {
      if (registry && registry.myPurchasedOrders.length > 0) {
        return registry.myPurchasedOrders.find(order => order && order.id === purchaseContextId) || null;
      } else {
        return acc;
      }
    }, null);
  }, [purchaseContextId, registries]);

  const { t } = useTranslation('guestRegistry');

  const { shoppingCartConfirmOrder, transactionCompleted } = useGuestRegistryTelemetry();

  const badUrl = () => {
    setTimeout(() => goBackToRegistry(), 10000);
  };

  if (error) {
    badUrl();
  }

  const getItemPhoto = useCallback(item => (item?.photos[0] !== undefined ? item.photos[0].url : undefined), []);

  const salesOrder = data?.registryPurchaseConfirmationDetailsByPurchaseContextId?.salesOrder;
  const items = salesOrder?.items || [];
  const firstItem = items[0];
  const brand = salesOrder?.paymentMethod?.card?.brand || '';
  const last4 = salesOrder?.paymentMethod?.card?.last4 || '';
  const isEdelivery = items.length > 0 ? items[0].isEdelivery : false;
  const isCashFund = !!items?.[0]?.isCashFund;

  let hasGiftWrapCard = false;
  const orderList = items.map(item => {
    if (item?.title === 'Personalized eCard') {
      hasGiftWrapCard = true;
    }
    return {
      product: { title: item.title, image: getItemPhoto(item) },
      quantity: item.quantity,
      price: formatCurrency({
        priceFloatingPointDecimalString: item.unitValue.floatingPointDecimalString,
        priceCurrencyCode: salesOrder?.subtotal.currency?.code,
        formatForm: 'short'
      }),
      totalPrice: formatCurrency({
        priceFloatingPointDecimalString: item.lineValue.floatingPointDecimalString,
        priceCurrencyCode: salesOrder?.subtotal.currency?.code,
        formatForm: 'short'
      })
    };
  });

  const selectedRegistryItem = useMemo(() => {
    const allRegistryItems = registries?.find(it => it.id === eventInfo?.eventId)?.items;
    return allRegistryItems ? allRegistryItems.find(item => item.productData.title === firstItem?.title) : undefined;
  }, [firstItem, eventInfo, registries]);

  const { backToRegistryBtn, giftPurchased, giftPurchasedOrderNumber, giftPurchasedEmail, giftPurchasedCouple } = t('confirmationPage');

  useEffect(() => {
    if (!salesOrder || loading || !eventFirebaseId) {
      return;
    }
    shoppingCartConfirmOrder({
      eventId: eventFirebaseId,
      itemsTotal: salesOrder.subtotal?.floatingPointDecimalString,
      shippingFee: salesOrder.shippingAndHandlingTotal?.floatingPointDecimalString,
      taxes: salesOrder.taxesTotal?.floatingPointDecimalString,
      orderTotal: salesOrder.total?.floatingPointDecimalString,
      orderId: salesOrder.id
    });
    transactionCompleted({
      checkoutType: 'dropship',
      salesOrderId: salesOrder?.id || '',
      giftName: firstItem?.title || 'giftName',
      hasGiftWrap: hasGiftWrapCard,
      price: hasGiftWrapCard ? `$${registryGiftWrapEcardPrice}` : undefined
    });
  }, [salesOrder, loading, eventFirebaseId, shoppingCartConfirmOrder, transactionCompleted, firstItem?.title, hasGiftWrapCard, registryGiftWrapEcardPrice]);

  return (
    <>
      {loading && !order && <TempSpinner />}
      {error && <ErrorSplash />}
      {!loading && !error && data && (
        <StyledSuccessPageContainer flexDirection={['column', null, 'row']} alignItems={['center', null, 'stretch']}>
          <StyledActionButtonsContainer __css={styles.actionButtonsContainerStyles}>
            <StyledActionButtonsWrapper __css={styles.actionButtonsWrapperStyles}>
              <StyledFormContainer __css={styles.formContainerStyles}>
                <Box marginBottom={7}>
                  <ResizedLogo />
                </Box>
                <TextV2 marginBottom={7} typographyVariant="hed6">
                  {giftPurchased()}
                </TextV2>
                <Flex>
                  <CheckOutline size={32} color="positive6" />
                  <TextV2 marginBottom={5} marginLeft={3} typographyVariant="hed2">
                    {giftPurchasedOrderNumber({ orderId: salesOrder?.id })}
                  </TextV2>
                </Flex>
                <TextV2 marginBottom={3} paddingRight={{ _: 6, sm: 0 }} typographyVariant="body1">
                  {giftPurchasedEmail()}
                </TextV2>
                {isGuestRoute && (
                  <TextV2 typographyVariant="body1" paddingRight={{ _: 6, sm: 0 }}>
                    {giftPurchasedCouple({ host })}
                  </TextV2>
                )}
              </StyledFormContainer>
              <Divider />
              {firstItem && selectedRegistryItem && !isCashFund && isGuestRoute && (
                <BuyForMyselfWrapper>
                  <BuyForMyselfFromPurchaseConfirmation item={firstItem} getItemPhoto={getItemPhoto} selectedRegistryItem={selectedRegistryItem} />
                </BuyForMyselfWrapper>
              )}
              <ButtonV2 variant="ghost" onClick={() => goBackToRegistry()} size="lg" intent="neutral" fullWidth marginTop={16}>
                {backToRegistryBtn()}
              </ButtonV2>
            </StyledActionButtonsWrapper>
          </StyledActionButtonsContainer>
          <StyledInformationContainer __css={styles.informationContainerStyles}>
            <StyledInformationWrapper __css={styles.informationWrapperStyles}>
              <JoyPurchasedOrder orderList={orderList} salesOrder={salesOrder} brand={brand} last4={last4} isEdelivery={isEdelivery} {...order} />
            </StyledInformationWrapper>
          </StyledInformationContainer>
          {firstItem && selectedRegistryItem && !isCashFund && isGuestRoute && (
            <BuyForMyselfWrapper isMobile>
              <BuyForMyselfFromPurchaseConfirmation item={firstItem} getItemPhoto={getItemPhoto} selectedRegistryItem={selectedRegistryItem} />
            </BuyForMyselfWrapper>
          )}
        </StyledSuccessPageContainer>
      )}
    </>
  );
};

export default DropshipPurchaseConfirmationV2;

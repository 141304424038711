import { Flex, styled, keyframes } from '@withjoy/joykit';

const slideIn = keyframes`
  100% { transform: translateX(0%); }
`;

export const StyledContainer = styled(Flex)`
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  animation: ${slideIn} 0.5s forwards;
  transition: width 0.5s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 6;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 50px 100px -24px rgba(44, 41, 37, 0.25), 0px 30px 60px -24px rgba(0, 0, 0, 0.3);
`;

import React from 'react';
import { Box, Divider, LinkV2, TextV2 } from '@withjoy/joykit';
import { useReservedItemController } from '../../ShoppingCartItem.controller';
import { useTranslation } from '@shared/core';
import { StyledProductPhoto, StylePurchaseOrderBox, StyledTitleText, styles as shoppingStyles } from '../../ShoppingCart.styles';
import { addRendition } from '@shared/utils/photoRendition';
import { NotificationToast } from '@apps/registry/common/components/NotificationToast';
import { PurchaseHistoryProps, CartItemProps } from '../../ShoppingCart.types';
import { GiftNote } from '../GiftNote';
import { useFeatureValue } from '@shared/core/featureFlags';
import { TrackingSection } from '../TrackingSection';
import { StyledPurchaseInfoText, styles } from './PurchaseHistory.styles';

const PurchasedOrder: React.FC<CartItemProps> = ({ orderId, paths, product, itemQuantity, eventId, isDropship, lineItem, note, fulfillment }) => {
  const { siteName, priceSpanMarkup, orderPrice, isDonationFundItem, toast, toastVisible, setToastVisibility, handleClickOrderButton } = useReservedItemController({
    product,
    itemQuantity,
    orderId,
    eventId,
    paths,
    isDropship,
    lineItem
  });
  const { t } = useTranslation('guestRegistry');
  const { quantity, orderNumber, trackingNumber, viewOrderDetails, didntPurchase } = t('shoppingCart');
  const enableAddEditGiftNote = useFeatureValue('enableAddEditGiftNote');
  const enableOrderTrackingFeature = useFeatureValue('orderTrackingFeature');

  return (
    <>
      <NotificationToast
        isPositionTop={true}
        noAnimation={!toast.showConfettiAnimation}
        notificationText={toast.text}
        notificationVisible={toastVisible}
        setNotificationVisible={setToastVisibility}
      />
      {product && orderId ? (
        <StylePurchaseOrderBox marginY={5}>
          <Box padding={15}>
            <Box display="grid" gridTemplateColumns="0.5fr 1fr 0.5fr" alignItems="center" columnGap={3}>
              <Box textAlign="center" backgroundColor={'mono2'} width="70px" height="70px">
                <StyledProductPhoto backgroundImage={addRendition({ url: product.image, renditionSize: 'medium', isProduct: true })} />
              </Box>
              <Box>
                <TextV2>{siteName}</TextV2>
                <StyledTitleText typographyVariant="hed2" component="p" __css={shoppingStyles.titleText}>
                  {product.title}
                </StyledTitleText>
              </Box>
              <TextV2 typographyVariant="hed2" textAlign="center">
                {orderPrice}
              </TextV2>
            </Box>
            <Box display="grid" gridTemplateColumns="0.5fr 1.5fr" alignItems="center" columnGap={3}>
              <Box gridColumn="2">
                {!isDonationFundItem && (
                  <StyledPurchaseInfoText __css={styles.purchaseInfoText}>{quantity({ quantity: itemQuantity, price: priceSpanMarkup })}</StyledPurchaseInfoText>
                )}
                {!isDropship && (
                  <>
                    {fulfillment?.orderNumber && (
                      <StyledPurchaseInfoText __css={styles.purchaseInfoText}>{orderNumber({ orderNumber: fulfillment.orderNumber })}</StyledPurchaseInfoText>
                    )}
                    {fulfillment?.shipments?.[0]?.trackingInformation?.trackingNumber && (
                      <StyledPurchaseInfoText __css={styles.purchaseInfoText}>
                        {trackingNumber({ trackingNumber: fulfillment.shipments[0].trackingInformation.trackingNumber })}
                      </StyledPurchaseInfoText>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
          {enableOrderTrackingFeature && !isDropship && <TrackingSection orderId={orderId} fulfillment={fulfillment} />}
          {enableAddEditGiftNote && <GiftNote orderId={orderId} note={note} />}
          <Divider />
          <LinkV2 textDecoration="none" onClick={handleClickOrderButton} color={'typeSecondaryDark'} textAlign="center">
            <TextV2 component="p" textAlign="center" padding={4} color={'typeSecondaryDark'}>
              {isDropship ? viewOrderDetails() : didntPurchase()}
            </TextV2>
          </LinkV2>
        </StylePurchaseOrderBox>
      ) : null}
    </>
  );
};

export const PurchaseHistory: React.FC<PurchaseHistoryProps> = ({ productList, orderId, isDropship, orderLineItems, eventId, paths, note, fulfillment }) => {
  const lineItem = orderLineItems?.filter(lineItem => lineItem.registryItem)[0];
  const product = productList.find(product => product.id === lineItem?.registryItem?.id);
  const orderItem = orderLineItems?.find(lineItem => {
    return lineItem.quantity;
  });
  return product && orderId ? (
    <PurchasedOrder
      paths={paths}
      orderId={orderId}
      isDropship={!!isDropship}
      lineItem={lineItem}
      itemQuantity={orderItem ? orderItem.quantity : 1}
      product={product}
      eventId={eventId}
      note={note}
      fulfillment={fulfillment}
    />
  ) : null;
};

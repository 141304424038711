import { Box, Flex, IconV2, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { CoupleNoteSVG } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/CoupleNote/index';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { pxToRem } from '@withjoy/joykit/theme';
import { StyledEventPhoto, StyledNote } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/CoupleNote/CoupleNote.styles';
import { addRendition } from '@shared/utils/photoRendition';
import { useRegistryHost } from '@apps/registry/common/utils/useRegistryHost';

type Props = {
  product: CookedProduct;
  eventPhoto?: string;
};
export const CoupleNote = ({ product, eventPhoto }: Props) => {
  // First sentence of the note should be bold. Source - https://regex101.com/library/4MMD3n
  const regExp = /(?<firstSentence>^.+?[a-z\d]{2,}[^\w]?[.?!]\s|$)(?<restNote>[\s\S]*)/gi;
  const match = regExp.exec(product.note);
  const firstSentence = match?.groups?.firstSentence || '';
  const restNote = match?.groups?.restNote || '';
  // Just playing it safe - there may be cases with an unacceptable combination of new lines and punctuation.
  const formattedNote = firstSentence && restNote ? `<b>${firstSentence}</b>${restNote}` : product.note;
  const host = useRegistryHost();
  return (
    <Flex paddingBottom={7} paddingX={[6, 0]} paddingTop={pxToRem(56)}>
      <IconV2 marginRight={5} color="mono4" size={62}>
        <CoupleNoteSVG />
      </IconV2>
      <Box maxWidth={['100%', pxToRem(400)]}>
        <StyledNote marginBottom={7} dangerouslySetInnerHTML={{ __html: formattedNote }} />
        <Flex alignItems="center">
          {eventPhoto && <StyledEventPhoto src={addRendition({ url: eventPhoto, renditionSize: 'avatar' })} />}
          <Box>
            <TextV2 typographyVariant="hed4">{host}</TextV2>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

import React, { CSSProperties } from 'react';
import { IconWrapper, StyledButtonContainer, StyledButtonWrapper, StyledCountContainer, styles } from './ShoppingCartOpenButtonV2.styles';
import { IconV2, StyleSystemProps, TextV2 } from '@withjoy/joykit';
import { CartIcon } from '@assets/icons';

type Props = {
  onClick: () => void;
  inCartProductCount: number;
  buttonContainerStyles?: CSSProperties;
  buttonWrapperStyles?: StyleSystemProps;
};

export const ShoppingCartOpenButtonV2 = ({ onClick, inCartProductCount, buttonContainerStyles, buttonWrapperStyles }: Props) => {
  return (
    <StyledButtonContainer onClick={onClick} style={buttonContainerStyles}>
      <StyledButtonWrapper __css={{ ...styles.buttonWrapperStyles, ...buttonWrapperStyles }}>
        <IconWrapper>
          <IconV2 size={20}>
            <CartIcon />
          </IconV2>
        </IconWrapper>
        <StyledCountContainer __css={styles.countContainerStyles}>
          <TextV2 typographyVariant="label3">{inCartProductCount > 99 ? '99+' : inCartProductCount}</TextV2>
        </StyledCountContainer>
      </StyledButtonWrapper>
    </StyledButtonContainer>
  );
};

import { useFormik } from 'formik';
import React, { useState, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { useAccountRouteHelper } from '@apps/account/useAccountHelper';
import { JoinEventAsPersonWithPasswordPayload, useJoinEventAsPersonWithPasswordMutation } from '@graphql/generated';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import { useTranslation } from '@shared/core';

export interface RegistryProtectedFields extends Readonly<{ password: string }> {}

export const useRegistryProtectedController = (eventId: string = '', eventHandle: string) => {
  const [showContactHost, setShowContactHost] = useState(false);
  const [joinEvent, { loading: joinEventLoading }] = useJoinEventAsPersonWithPasswordMutation();
  const { getRegistryProtectedTranslations } = useRegistryGuestTranslations();
  const { incorrectPass } = getRegistryProtectedTranslations();
  const { t: translateForm } = useTranslation('generalFormValidation');

  const registryProtectedFormik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object<RegistryProtectedFields>({
      password: Yup.string().required(translateForm('required'))
    }),
    onSubmit: values => {}
  });

  const joinEventWithPassword = useCallback(
    async (eventId: string, payload: JoinEventAsPersonWithPasswordPayload) => {
      try {
        await joinEvent({ variables: { eventId, payload }, refetchQueries: () => ['CheckCanAccessPage'], awaitRefetchQueries: true });
      } catch (e) {
        registryProtectedFormik.setFieldError('password', incorrectPass);
      }
    },
    [joinEvent, registryProtectedFormik, incorrectPass]
  );

  const { signInAndReturnToCurrentPath } = useAccountRouteHelper();

  const handleSubmit = useMemo(
    () => (e: React.SyntheticEvent) => {
      e.preventDefault();
      joinEventWithPassword(eventId, { eventPassword: registryProtectedFormik.values.password });
    },
    [eventId, joinEventWithPassword, registryProtectedFormik.values.password]
  );

  const handleSigninClick = useMemo(
    () => async () => {
      signInAndReturnToCurrentPath();
    },
    [signInAndReturnToCurrentPath]
  );

  return {
    showContactHost,
    setShowContactHost,
    registryProtectedFormik,
    handleSubmit,
    handleSigninClick,
    joinEventLoading
  };
};

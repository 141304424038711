import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { ShippingAddressFragment } from '@graphql/aliases';
import { getLocalStorage } from '@shared/core';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useDisclosure } from '@withjoy/joykit';

import { CartCheckoutSteps, CartSections, LineItem, HistoryItem, ProductListV2, ShippingAddressOptions, ShippingMethods } from '../ShoppingCart.types';
import { DetailsFormFields } from '../ShoppingCartInnerV2/steps/DetailsStep/types';
import {
  GetItemCheckoutMechanismsDocument,
  GetItemCheckoutMechanismsQuery,
  RegistryLineItemInput,
  RegistryOrderFragment,
  ShippingAddressInput,
  useGetCalculateRegistryPurchaseInfoQuery,
  useGetEventRegistriesAndOrdersLazyQuery
} from '@graphql/generated';
import {
  STATE_INITIAL,
  NATIONAL_COUNTRY_CODE,
  getInitialState,
  getUpdatedStateByUpdateItem,
  getUpdatedStateByRemoveItem,
  getUpdatedStateByAddItem,
  getInStock,
  getStillNeeded,
  formatAddress,
  DERIVED_STATE_INITIAL,
  NATIONAL_COUNTRY,
  getInitialHistoryState,
  getMarkedAsVisited,
  HISTORY_STATE_INITIAL
} from './ShoppingCart.utils';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { noop } from 'lodash-es';

const localStorage = getLocalStorage();

export const SHOPPING_CART_INITIAL: ProviderProps = {
  state: { ...STATE_INITIAL, ...HISTORY_STATE_INITIAL, ...DERIVED_STATE_INITIAL },
  mutators: {
    // Storage
    addItem: noop,
    removeItem: noop,
    updateItem: noop,
    clearState: noop,

    // History State
    markAsVisited: noop,

    // Section & Step
    updateCurrentSection: noop,
    updateCurrentCheckoutStep: noop,

    // Shipping Step
    updateShippingOption: noop,
    updateShippingAddress: noop,
    updateShippingMethod: noop,

    // Details Step
    updateDetails: noop,

    // General Cart
    openShoppingCart: noop,
    closeShoppingCart: noop,
    buyNowFromShoppingCart: noop,
    setVerificationStatus: noop
  }
};

export type HistoryState = {
  historyItemList: Array<HistoryItem>;
};
export type State = {
  itemList: Array<LineItem>;
  lastUpdated: number | null;
  hadOnlyStillNeededItems: boolean;
};

export type VerificationStatus = 'idle' | 'started' | 'fetchMechanisms' | 'mechanisms' | 'done';

export type DerivedState = {
  // Step & Section
  checkoutStepsFlow: CartCheckoutSteps[];
  currentSection: CartSections;
  currentCheckoutStep: CartCheckoutSteps;

  // Shipping Step
  shippingMethod: ShippingMethods;
  selectedShippingOption: ShippingAddressOptions;
  shippingAddress?: Maybe<ShippingAddressFragment>;
  coupleAddress?: Maybe<ShippingAddressFragment>;
  customShippingAddress?: Maybe<ShippingAddressFragment>;
  shippingFee?: Maybe<string>;
  shippingFeeInUnits: number;

  // Details Step
  details: DetailsFormFields;

  // General Cart
  isShoppingCartOpen: boolean;
  itemListCount: number;

  // Payment Step
  verificationStatus: VerificationStatus;
};

export type GeneralState = State & HistoryState & DerivedState;
export type Mutators = {
  // State
  addItem: (data: LineItem, maxQuantity: number) => void;
  removeItem: (registryItemId: string) => void;
  updateItem: (data: LineItem) => void;
  clearState: () => void;

  // History State
  markAsVisited: (reservedOrderList: RegistryOrderFragment[], productList: ProductListV2) => void;

  // Section & Step
  updateCurrentSection: (section: CartSections) => void;
  updateCurrentCheckoutStep: (step: CartCheckoutSteps) => void;

  // Shipping Step
  updateShippingAddress: (address: ShippingAddressFragment) => void;
  updateShippingOption: (option: ShippingAddressOptions) => void;
  updateShippingMethod: (method: ShippingMethods) => void;

  // Details Step
  updateDetails: (details: DetailsFormFields) => void;

  // General Cart
  closeShoppingCart: () => void;
  openShoppingCart: () => void;
  buyNowFromShoppingCart: (data: LineItem, maxQuantity: number) => void;
  setVerificationStatus: (newState: VerificationStatus) => void;
};

export interface ProviderProps {
  state: GeneralState;
  mutators: Mutators;
}

export const ShoppingCartContext = createContext(SHOPPING_CART_INITIAL);

type ShoppingCartProviderProps = {
  coupleAddress?: Maybe<ShippingAddressFragment>;
  checkoutStepsFlow: CartCheckoutSteps[];
  eventHandle: string;
  eventId?: string;
};

export const ShoppingCartProvider: React.FC<ShoppingCartProviderProps> = ({ children, coupleAddress, eventHandle, eventId, checkoutStepsFlow }) => {
  const shoppingCartKey = `shoppingCart_${eventId}`;
  const shoppingCartHistoryKey = `shoppingCartHistory_${eventId}`;
  const { Provider } = ShoppingCartContext;

  // Section & step state
  const [currentSection, setCurrentSection] = useState(CartSections.CART);
  const [currentCheckoutStep, setCurrentCheckoutStep] = useState(CartCheckoutSteps.Cart);

  // Shipping Step
  // If guest chooses to ship to themselves or to another US address
  const [customShippingAddress, setCustomShippingAddress] = useState<ShippingAddressFragment | null>({
    name: '',
    address1: '',
    city: '',
    countryCode: NATIONAL_COUNTRY_CODE,
    country: NATIONAL_COUNTRY,
    state: '',
    validated: false
  });
  const [selectedShippingOption, setSelectedShippingOption] = useState<ShippingAddressOptions>(
    !!coupleAddress ? ShippingAddressOptions.CoupleAddress : ShippingAddressOptions.AnotherAddress
  );
  const [shippingMethod, setShippingMethod] = useState<ShippingMethods>(ShippingMethods.FreeByJoy);
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>('idle');
  // selectedShippingAddress variable stores wether the couple address or form values from address form.
  // So if user filled the form, then clicked "ship to couple" and then clicked "shipped to me" again - he will see the pre-filled form

  const shippingAddress = useMemo(() => {
    const defaultAddress = { ...coupleAddress!, __typename: undefined };
    return selectedShippingOption === ShippingAddressOptions.CoupleAddress ? defaultAddress : customShippingAddress;
  }, [coupleAddress, customShippingAddress, selectedShippingOption]);
  // Details Step
  const [details, setDetails] = useState<DetailsFormFields>({
    name: '',
    message: '',
    email: ''
  });
  const isMobileOrTablet = useIsMobileOrTablet();

  // General cart
  const { isOpen, onClose, onOpen } = useDisclosure();

  const closeShoppingCart = () => {
    setCurrentSection(CartSections.CART);
    setCurrentCheckoutStep(CartCheckoutSteps.Cart);
    onClose();
  };

  // History state
  const [historyState, setHistoryState] = useState<HistoryState>(getInitialHistoryState(shoppingCartHistoryKey));
  const markAsVisited = (reservedOrderList: RegistryOrderFragment[], productList: ProductListV2) => {
    setHistoryState((prevState: HistoryState) => getMarkedAsVisited(prevState, reservedOrderList, productList));
  };

  // State
  const [state, setState] = useState<State>(getInitialState(shoppingCartKey));
  const [getEventRegistriesAndOrders, { data, loading }] = useGetEventRegistriesAndOrdersLazyQuery({
    batchMode: 'fast',
    fetchPolicy: 'no-cache',
    variables: { eventHandle }
  });
  const client = useApolloClient();
  const [checkoutMechanisms, setCheckoutMechanisms] = useState<Array<ApolloQueryResult<GetItemCheckoutMechanismsQuery>>>([]);

  const lineItems: RegistryLineItemInput[] = state.itemList.map(item => ({ quantity: item.quantity, registryItemId: item.registryItemId }));
  const shippingAddressInput: ShippingAddressInput | undefined = shippingAddress ? (({ hash, ...rest }) => rest)(shippingAddress) : undefined;

  const { data: shippingData } = useGetCalculateRegistryPurchaseInfoQuery({
    variables: {
      payload: {
        eventId: eventId!,
        eCard: details.eCard ?? undefined,
        address: shippingAddressInput,
        lineItems
      }
    },
    skip: !shippingAddressInput || lineItems.length === 0 || !eventId,
    batchMode: 'fast'
  });

  const getCheckoutMechanisms = useCallback(
    (itemList: LineItem[]) => {
      if (!client) return;
      Promise.all(
        itemList.map(item =>
          client.query({
            query: GetItemCheckoutMechanismsDocument,
            fetchPolicy: 'no-cache',
            variables: { id: item.registryItemId, shippingAddress: formatAddress(shippingAddress!) }
          })
        )
      )
        .then((fetchedCheckoutMechanisms: Array<ApolloQueryResult<GetItemCheckoutMechanismsQuery>>) => {
          setCheckoutMechanisms(fetchedCheckoutMechanisms);
        })
        .catch(() => {
          setCheckoutMechanisms([]);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [client, shippingAddress]
  );

  const addItem = (data: LineItem, maxQuantity: number) => {
    setState((prevState: State) => getUpdatedStateByAddItem(prevState, data, maxQuantity));

    if (!isMobileOrTablet) {
      onOpen();
    }
    setCurrentSection(CartSections.CART);
  };

  const removeItem = (registryItemId: string) => {
    setState((prevState: State) => getUpdatedStateByRemoveItem(prevState, registryItemId));
  };

  const updateItem = (data: LineItem) => {
    setState((prevState: State) => getUpdatedStateByUpdateItem(prevState, data));
  };

  const clearState = () => {
    setState(STATE_INITIAL);
  };

  // Trigger cleanup at first render and polling every 5 mins - remove out of stock products
  useEffect(() => {
    getCheckoutMechanisms(state.itemList);

    const timer = setInterval(() => getCheckoutMechanisms(state.itemList), 1000 * 60 * 5);

    return () => {
      clearInterval(timer);
    };
  }, [getCheckoutMechanisms, state.itemList]);

  // Cleanup remove already purchased/reserved products

  useEffect(() => {
    if (verificationStatus === 'started') {
      if (loading || !data) {
        getEventRegistriesAndOrders();
      } else {
        setState((prevState: State) => getStillNeeded(prevState, data));
        setVerificationStatus('done');
      }
    }
  }, [data, loading, getEventRegistriesAndOrders, setVerificationStatus, setState, verificationStatus]);

  // Cleanup remove out of stock products
  useEffect(() => {
    if (verificationStatus === 'mechanisms') {
      setState((prevState: State) => getInStock(prevState, checkoutMechanisms));
      setVerificationStatus('done');
    }
  }, [verificationStatus, setVerificationStatus, setState, getCheckoutMechanisms, checkoutMechanisms, state.itemList]);

  // Update local storage
  useEffect(() => {
    if (!eventId) {
      return;
    }
    localStorage.setItem(shoppingCartKey, JSON.stringify(state));
  }, [state, shoppingCartKey, eventId]);

  // Update history local storage
  useEffect(() => {
    if (!eventId) {
      return;
    }
    localStorage.setItem(shoppingCartHistoryKey, JSON.stringify(historyState));
  }, [historyState, shoppingCartHistoryKey, eventId]);

  const itemListCount = useMemo(() => state.itemList.reduce((total, product) => (product.inStock ? total + product.quantity : total), 0), [state]);
  const buyNowFromShoppingCart = (data: LineItem, maxQuantity: number) => {
    // add the current item to the cart
    addItem(data, maxQuantity);
    //open the shopping cart properly so data is propagated correctly - specially for mobile.
    onOpen();
    //set the shipping address checkout step.
    setCurrentCheckoutStep(CartCheckoutSteps.Shipping);
  };
  const shippingFee =
    shippingData?.calculateRegistryPurchaseInfo?.shippingAndHandlingTotal?.floatingPointDecimalString && lineItems.length > 0
      ? shippingData?.calculateRegistryPurchaseInfo?.shippingAndHandlingTotal?.floatingPointDecimalString
      : undefined;

  const shippingFeeInUnits = shippingData?.calculateRegistryPurchaseInfo?.shippingAndHandlingTotal?.valueInMinorUnits
    ? shippingData?.calculateRegistryPurchaseInfo?.shippingAndHandlingTotal?.valueInMinorUnits / 100
    : 0;
  return (
    <Provider
      value={{
        state: {
          // State
          checkoutStepsFlow,
          itemList: state.itemList,
          itemListCount,
          lastUpdated: state.lastUpdated,
          hadOnlyStillNeededItems: state.hadOnlyStillNeededItems,

          // History state
          historyItemList: historyState.historyItemList,

          // Section & Step
          currentSection,
          currentCheckoutStep,

          // General Cart
          isShoppingCartOpen: isOpen,

          // Shipping Step
          coupleAddress,
          customShippingAddress,
          shippingAddress,
          shippingMethod,
          selectedShippingOption,
          shippingFee,
          shippingFeeInUnits,

          // Details step
          details,

          // Payment step
          verificationStatus
        },
        mutators: {
          // State
          addItem,
          removeItem,
          updateItem,
          clearState,

          // History State
          markAsVisited,

          // Section & Step
          updateCurrentSection: setCurrentSection,
          updateCurrentCheckoutStep: setCurrentCheckoutStep,

          // General Cart
          closeShoppingCart,
          openShoppingCart: onOpen,
          buyNowFromShoppingCart,

          // Shipping Step
          updateShippingAddress: setCustomShippingAddress,
          updateShippingOption: setSelectedShippingOption,
          updateShippingMethod: setShippingMethod,

          // Details step
          updateDetails: setDetails,

          // Payment step
          setVerificationStatus
        }
      }}
    >
      {children}
    </Provider>
  );
};

import React, { useMemo } from 'react';
import { RoutesProvider, RoutesPaths, useRouterHelper } from '@shared/core';

export type RoutesNames = 'guestRegistry' | 'purchase' | 'retailerRedirect' | 'contribute' | 'shoppingCart';

export const routePaths: RoutesPaths<RoutesNames> = {
  guestRegistry: {
    path: '',
    goToPath: () => 'registry'
  },
  purchase: {
    path: 'purchase',
    goToPath: () => 'purchase'
  },
  retailerRedirect: {
    path: 'retailer-redirect',
    goToPath: () => 'retailer-redirect'
  },
  contribute: {
    path: 'contribute',
    goToPath: () => 'contribute'
  },
  shoppingCart: {
    path: 'shopping-cart',
    goToPath: () => 'shopping-cart'
  }
};

export const useRoutePaths = () => {
  const { buildPath } = useRouterHelper();
  const memoizedPaths = useMemo(() => {
    return (Object.keys(routePaths) as RoutesNames[]).reduce((acc, route) => {
      acc[route] = {
        path: buildPath(routePaths[route].path),
        goToPath: routePaths[route].goToPath
      };
      return acc;
    }, {} as RoutesPaths<RoutesNames>);
  }, [buildPath]);
  return memoizedPaths;
};

export const GuestRegistryRouter: React.FC<{ eventHandle: string }> = ({ eventHandle, children }) => {
  return (
    <RoutesProvider isGuestRoute={true} handle={eventHandle} appUrl="registry">
      {children}
    </RoutesProvider>
  );
};

import { Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { JoyLogo } from '@assets/index';

export const StyledScrollableFlex = styled(Flex)`
  flex-grow: 1;
  overflow: auto;
  flex-direction: column;
`;

export const ResizedLogo = styled(JoyLogo)`
  width: auto;
  height: ${pxToRem(38)};
`;

export const ContentFlex = styled(Flex)``;
export const ContentContainer = styled(Flex)``;
export const ContentWrapper = styled(Flex)``;

const contentWrapperStyles: StyleSystemProps = {
  flexDirection: 'column',
  paddingX: { _: 6, md2: 0 },
  paddingTop: {
    _: 9,
    md2: 10
  },
  width: '100%',
  maxWidth: {
    _: '100%',
    sm: pxToRem(450)
  },
  alignItems: 'center'
};

const contentContainerStyles: StyleSystemProps = {
  width: '100%',
  flex: 1,
  justifyContent: 'center'
};

const contentFlexStyles: StyleSystemProps = {
  height: '100%',
  flexDirection: { _: 'column', md1: 'row' }
};

export const styles = {
  contentWrapperStyles,
  contentContainerStyles,
  contentFlexStyles
};

import { addRendition } from '@shared/utils/photoRendition';
import React from 'react';
import { DEFAULT_CUSTOM_ITEM_IMAGE } from '../../../../Catalog.constants';
import { CatalogProductMediaAsset } from '../../../../Catalog.types';
import { StyledPhotoSpotlightContainer, StyledProductPhoto, styles } from './ProductPhoto.styles';
import CustomCursor from '../GalleryLightbox/assets/custom-cursor-plus.svg';

type ProductPhotoProps = {
  isCoverImage?: boolean;
  photoUrl: Maybe<string>;
  style?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: string | React.ComponentType<any> | undefined;
  onClick?: (mediaAsset: CatalogProductMediaAsset) => void;
  isBackgroundColorEnabled?: boolean;
};

export const ProductPhoto: React.FC<ProductPhotoProps> = ({ isCoverImage, photoUrl, style, as, isBackgroundColorEnabled, onClick }) => {
  return (
    <StyledPhotoSpotlightContainer
      as={as ?? 'div'}
      style={style}
      __css={styles.photoSpotlightContainer}
      cursor={onClick ? `url('${CustomCursor}') 22 22, pointer` : undefined}
      onClick={onClick}
    >
      <StyledProductPhoto
        __css={styles.productPhoto}
        backgroundImage={`url(${
          photoUrl
            ? addRendition({
                url: photoUrl,
                renditionSize: 'medium',
                isProduct: true
              })
            : `{${DEFAULT_CUSTOM_ITEM_IMAGE}}`
        })`}
        backgroundSize={isCoverImage ? 'cover' : 'contain'}
        isBackgroundColorEnabled={isBackgroundColorEnabled}
      />
    </StyledPhotoSpotlightContainer>
  );
};

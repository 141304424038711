import React from 'react';
import { FormikProps } from 'formik';
import { isNumber } from 'lodash-es';

import { useTranslation } from '@shared/core';
import { ButtonV2, FormControl, InputV2, SpacingStack, TextV2 } from '@withjoy/joykit';

import { AddressAutocompleteField } from '@apps/registry/common/components/AddressAutocompleteField';
import { useShoppingCart } from '@apps/registry/common/components/ShoppingCart/state';

import { AddressFormFields } from '../types';
import { AddressUsedEnum } from '@apps/registry/common/components/AddressDialog/types';
import { ShippingFormFields } from '../ShippingStep.controller';

type Props = {
  formFields: ShippingFormFields;
  handleSaveClick: () => void;
  handleCancelClick: () => void;
  formik: FormikProps<AddressFormFields>;
  setSuggestedAddress: (address: AddressFormFields | null) => void;
  setAddressUsed: (addressUsed: AddressUsedEnum) => void;
};

const NATIONAL_COUNTRY_CODE = 'USA';

export const AddressForm = ({ formFields, handleSaveClick, handleCancelClick, formik, setSuggestedAddress, setAddressUsed }: Props) => {
  const { getFieldProps, handleChange, isFormValid, errors } = formFields;
  const { nameError, stateError, cityError, postalCodeError } = errors;
  const { t2 } = useTranslation('guestRegistry');
  const {
    nameLabel,
    nameCaption,
    addressLine1Label,
    addressLine1Caption,
    addressLine2Label,
    cityLabel,
    stateLabel,
    postalCodeLabel,
    requiredFields,
    saveButton,
    cancelButton
  } = t2('shoppingCart', 'shippingAddressStep', 'addressForm');

  const {
    state: { customShippingAddress }
  } = useShoppingCart();

  const handlePostalCodeFieldChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value;
    const charParsed = parseInt(value.charAt(value.length - 1));
    if (value === '' || (value.length <= 5 && isNumber(charParsed))) {
      handleChange(event);
    }
  };

  return (
    <form>
      <SpacingStack spacing={6}>
        <FormControl
          label={nameLabel}
          caption={
            <TextV2 typographyVariant="label2" color="mono10">
              {nameCaption}
            </TextV2>
          }
          isInvalid={!!nameError}
          error={nameError}
          isRequired
        >
          <InputV2 {...getFieldProps('name')} placeholder="Jamie Lee" />
        </FormControl>
        <AddressAutocompleteField
          label={addressLine1Label}
          caption={addressLine1Caption}
          placeholderError={addressLine1Caption}
          registryShippingAddress={customShippingAddress || null}
          formik={formik}
          formikKey={'address1'}
          isNationalAddress
          countryDropdownValue={NATIONAL_COUNTRY_CODE}
          clearAddressPressed={false}
          setSuggestedAddress={setSuggestedAddress}
          setAddressUsed={setAddressUsed}
        />
        <FormControl label={addressLine2Label}>
          <InputV2 {...getFieldProps('address2')} autoComplete="none" />
        </FormControl>
        <FormControl label={cityLabel} isInvalid={!!cityError} error={cityError} isRequired>
          <InputV2 {...getFieldProps('city')} />
        </FormControl>
        <FormControl label={stateLabel} isInvalid={!!stateError} error={stateError} isRequired>
          <InputV2 {...getFieldProps('state')} />
        </FormControl>
        <FormControl label={postalCodeLabel} isInvalid={!!postalCodeError} error={postalCodeError} isRequired>
          <InputV2 {...getFieldProps('postalCode')} onChange={handlePostalCodeFieldChange} />
        </FormControl>
        <TextV2 typographyVariant="label3">{requiredFields}</TextV2>
      </SpacingStack>
      <SpacingStack spacing={5} marginTop={6}>
        <ButtonV2 intent="neutral" onClick={handleSaveClick} type="submit" fullWidth disabled={!isFormValid || !getFieldProps('name').value}>
          {saveButton}
        </ButtonV2>
        <ButtonV2 intent="neutral" variant="ghost" onClick={handleCancelClick} fullWidth>
          {cancelButton}
        </ButtonV2>
      </SpacingStack>
    </form>
  );
};

import { useGetRegistryPurchaseConfirmationDetailsQuery } from '@graphql/generated';
import { useRouteMatch } from '@react-router';
import { withWindow } from '@shared/utils/withWindow';
import { usePurchaseContextIdFromLocation } from '@apps/registry/common/utils/dropship';
import { useShoppingCart } from '../ShoppingCart/state';

export const useDropshipPurchaseConfirmationController = () => {
  const purchaseContextId = usePurchaseContextIdFromLocation();
  const {
    mutators: { clearState }
  } = useShoppingCart();

  const { data, loading, error } = useGetRegistryPurchaseConfirmationDetailsQuery({
    batchMode: 'fast',
    variables: purchaseContextId ? { purchaseContextId } : undefined,
    skip: !purchaseContextId
  });
  const route = useRouteMatch<{ weddingName: string; page: string; edit: string }>(['/:weddingName/:edit?/registry/purchase']);

  const goToWeddingWebsite = () => {
    let pathname = route?.params.weddingName || '';
    if (route?.params.edit) {
      pathname += `/${route.params.edit}`;
    }
    withWindow(global => (global.location.href = `${global.location.origin}/${pathname}`));
  };

  const goBackToRegistry = () => {
    let pathname = route?.params.weddingName || '';
    if (route?.params.edit) {
      pathname += `/${route.params.edit}`;
    }
    withWindow(global => (global.location.href = `${global.location.origin}/${pathname}/registry`));
  };

  if (data) {
    clearState();
  }

  return { data, loading, error, goToWeddingWebsite, goBackToRegistry };
};

import React, { useEffect, useCallback, useContext } from 'react';
import { TextV2, Box } from '@withjoy/joykit';
import { StyledBoxMob, StyledCheckoutButton } from './ShoppingCart.styles';
import { NotificationBar, ShoppingCartOpenButtonV2 } from './components';
import { useShoppingCartController } from './ShoppingCart.controller';
import { useTranslation } from '@shared/core';
import { ReactComponent as CartIcon } from '@assets/icons/cart-icon.svg';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { ShoppingCartDialogV2 } from './ShoppingCartDialogV2';
import { RegistryOrderList, RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { ShippingAddressFragment } from '@graphql/aliases';
import { withWindow } from '@shared/utils/withWindow';
import { useHistory } from '@react-router';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useShoppingCart } from '@apps/registry/common/components/ShoppingCart/state';
import { OrderTrackingContext } from '@apps/registry/guest/providers';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useFeatureValue } from '@shared/core/featureFlags';
import { CurrentOrder, usePurchaseConfirmationProvider } from '../../../guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.provider';
import { getSortedClickPurchaseHistory, resetAttemptToGetResponse } from './utils/trackPurchaseClicks';
import { ModalStates } from '../../../guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';
import { PurchaseConfirmationModal } from '../../../guest/routes/Purchase/components/PurchaseConfirmationModal';
import { ToastVariableProps } from '../../../guest/routes/GuestRegistry/GuestRegistry.controller';
import { GuestRegistryState } from '@apps/registry/guest/GuestRegistry.controller';
import { useMount } from '@shared/utils/hooks/useMount';
import { CartSections } from './ShoppingCart.types';

interface ShoppingCartProps
  extends Readonly<{
    eventId: string;
    orders: RegistryOrderList;
    registry: RegistryList;
    shippingAddress: ShippingAddressFragment | null;
    registryCurrencyCode?: string;
    isShowCartSection: boolean;
    openToast: (toast: ToastVariableProps) => void;
    eventHandle: string;
    registryState: GuestRegistryState;
  }> {}

export const ShoppingCartV2: React.FC<ShoppingCartProps> = ({ eventId, registry, orders, registryCurrencyCode, isShowCartSection, openToast, eventHandle, registryState }) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const { t } = useTranslation('guestRegistry');
  const { viewGift } = t('shoppingCart');

  const paths = useRoutePaths();

  const history = useHistory();
  const previousUrl = history.location.pathname;

  const {
    helpDialogOpen,
    productList,
    reservedOrderList,
    purchasedOrderList,
    orderCount,
    cartOrderCount,
    cartTotal,
    inCartProductList,
    inCartProductCount,
    inCartProductTotal,
    showHelpDialog,
    handleDialogClose,
    showShoppingCart
  } = useShoppingCartController({
    registries: registry,
    orders,
    registryCurrencyCode
  });

  const { updateOrderDataFromOrderId } = useGuestRegistryState();

  const enableHaveYouPurchasedModal = useFeatureValue('enableHaveYouPurchasedModal');

  const {
    isOpen,
    modalData,
    closeModal,
    openModal,
    purchaseHistoryData: { remainingPurchaseClickHistoryAttempts, attemptToShowPurchaseClickHistory, setAttemptToShowPurchaseClickHistory, currentPurchaseHistoryItem }
  } = usePurchaseConfirmationProvider();

  const { logShoppingCartLoad } = useGuestRegistryTelemetry({ page: 'registry' });

  useEffect(() => {
    logShoppingCartLoad({
      isShown: isShowCartSection || showShoppingCart,
      items: reservedOrderList.concat(purchasedOrderList).map(order => {
        const registryItem = order.lineItems?.find(lineItem => lineItem.registryItem && lineItem.quantity);
        return {
          productTitle: registryItem?.frozenProductData?.title,
          price: registryItem?.frozenProductData?.price.floatingPointDecimalString
        };
      })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAttemptToShowPurchaseClickHistory(true);
    withWindow(window => {
      window.onbeforeunload = () => {
        resetAttemptToGetResponse();
      };
    });
    return () => {
      resetAttemptToGetResponse();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enableHaveYouPurchasedModal) {
      const items = getSortedClickPurchaseHistory();
      const showReminderDialog = items?.length && !items[currentPurchaseHistoryItem].attemptedToGetResponse && attemptToShowPurchaseClickHistory;
      if (showReminderDialog) {
        const orderId = items[currentPurchaseHistoryItem].reservedOrderId;
        const productId = items[currentPurchaseHistoryItem].productId;
        updateOrderDataFromOrderId(orderId || '', productId, productList.products);
        openModal({ orderId, productId, modalStateToBeOpened: ModalStates.CONFIRM_PURCHASED });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingPurchaseClickHistoryAttempts, attemptToShowPurchaseClickHistory]);

  const {
    state: { isShoppingCartOpen },
    mutators: { openShoppingCart, closeShoppingCart, markAsVisited, updateCurrentSection }
  } = useShoppingCart();
  const { isAddExternalOrderTrackingDialogOpen, orderForTrackingRequest, closeAddExternalOrderTrackingDialog } = useContext(OrderTrackingContext);

  const showShoppingCartDialog = useCallback(() => {
    withWindow(window => window.history.pushState(null, 'product', window.location.href));
    markAsVisited(reservedOrderList, productList);
    openShoppingCart();
  }, [openShoppingCart, markAsVisited, reservedOrderList, productList]);

  useMount(() => {
    withWindow(({ location }) => {
      // eslint-disable-next-line compat/compat
      const params = new URLSearchParams(location.search);
      const shoppingCartParam = params.get('shopping-cart');
      const openHistoryTab = shoppingCartParam === 'active' || shoppingCartParam === 'history';
      const openCartTab = shoppingCartParam === 'cart';
      const openCart = (openHistoryTab && isMobileOrTablet) || openCartTab;
      if (openCart) {
        if (openHistoryTab) updateCurrentSection(CartSections.HISTORY);
        showShoppingCartDialog();
      }
    });
  });

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        withWindow(window => window.history.replaceState(null, 'product', previousUrl));
        closeShoppingCart();
      }
    });
  }, [history, closeShoppingCart, previousUrl]);

  return (
    <>
      {isOpen && (
        <PurchaseConfirmationModal
          isOpen={isOpen}
          onClose={closeModal}
          registry={registry}
          eventId={eventId}
          isAdmin={registryState.isAdmin}
          eventHandle={eventHandle}
          shippingAddress={registryState.shippingAddress}
          productId={(modalData as CurrentOrder).productId}
          orderId={(modalData as CurrentOrder).orderId}
          openToast={openToast}
          modalStateToBeOpened={(modalData as CurrentOrder).modalStateToBeOpened}
        />
      )}
      {!isShoppingCartOpen && <NotificationBar productList={productList} reservedOrderList={reservedOrderList} />}
      <ShoppingCartDialogV2
        isOpen={isShoppingCartOpen}
        eventId={eventId}
        helpDialogOpen={helpDialogOpen}
        isAddExternalOrderTrackingDialogOpen={isAddExternalOrderTrackingDialogOpen}
        productList={productList}
        reservedOrderList={reservedOrderList}
        purchasedOrderList={purchasedOrderList}
        inCartProductList={inCartProductList}
        inCartProductCount={inCartProductCount}
        inCartProductTotal={inCartProductTotal}
        orderCount={orderCount}
        cartOrderCount={cartOrderCount}
        cartTotal={cartTotal}
        orderForTrackingRequest={orderForTrackingRequest}
        paths={paths}
        showHelpDialog={showHelpDialog}
        handleDialogClose={handleDialogClose}
        closeAddExternalOrderTrackingDialog={closeAddExternalOrderTrackingDialog}
        registryCurrencyCode={registryCurrencyCode}
      />
      {isMobileOrTablet ? (
        inCartProductCount > 0 && (
          <StyledBoxMob>
            <StyledCheckoutButton intent="primary" variant="solid" onClick={showShoppingCartDialog}>
              <Box alignItems="center" display="inline-flex">
                <Box display="flex">
                  <CartIcon />
                  {inCartProductCount > 0 && (
                    <TextV2 typographyVariant="hed2" color={'white'}>
                      {inCartProductCount}
                    </TextV2>
                  )}
                </Box>
                <Box display="flex">
                  <TextV2 typographyVariant="hed2" color={'white'} marginLeft={6}>
                    {viewGift()}
                  </TextV2>
                </Box>
              </Box>
            </StyledCheckoutButton>
          </StyledBoxMob>
        )
      ) : (
        <ShoppingCartOpenButtonV2 onClick={showShoppingCartDialog} inCartProductCount={inCartProductCount} />
      )}
    </>
  );
};

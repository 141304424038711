import React, { useCallback } from 'react';
import { Flex, Box, TextV2, LinkV2 } from '@withjoy/joykit';
import { ReactComponent as CheckIcon } from '@assets/icons/mark-check-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { useReservedItemController } from '../../ShoppingCartItem.controller';
import { StyledItemBox, StyleCheckbox, StyleRemoveButton, StyledProductPhoto, StyleActionText, styles, StyleButton, StyleLink, StyledTitleText } from '../../ShoppingCart.styles';
import { useTranslation } from '@shared/core';
import { addRendition } from '@shared/utils/photoRendition';
import { NotificationToast } from '@apps/registry/common/components/NotificationToast';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { RegistryOrderFragment } from '@graphql/generated';
import { CancelItemConfirmationProps, ShoppingCartCommonProps } from '../../ShoppingCart.types';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { ModalStates } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';
import { useRegistryHost } from '@apps/registry/common/utils/useRegistryHost';

const CancelItemConfirmation: React.FC<CancelItemConfirmationProps> = ({ shoppingCartProp, handleRemoveClick, handleClickCancel, removeItemReason }) => {
  const { t } = useTranslation('guestRegistry');
  const { removeTitle, removeItem, cancel } = t('shoppingCart');

  return (
    <Flex alignItems="center" justifyContent="center">
      <StyledItemBox width="100%" padding="0px 40px">
        <TextV2 typographyVariant="body1" marginY={6} fontWeight={600}>
          {removeTitle()}
        </TextV2>
        {shoppingCartProp.removeAction.map(({ value, label, onChange }) => (
          <StyleCheckbox key={value} label={label} id={value} name={value} value={value} checked={removeItemReason === value ? true : false} onChange={onChange} />
        ))}
        <Flex alignItems="center" justifyContent="center" marginY={6}>
          <StyleRemoveButton onClick={handleRemoveClick}>{removeItem()}</StyleRemoveButton>
        </Flex>
        <Flex alignItems="center" justifyContent="center" marginY={6}>
          <StyleLink underline="none" onClick={handleClickCancel} color={'typeSecondaryDark'} isCancel={true}>
            <TextV2 typographyVariant="body1" display="flex" alignItems="center" color={'typeSecondaryDark'}>
              {cancel()}
            </TextV2>
          </StyleLink>
        </Flex>
      </StyledItemBox>
    </Flex>
  );
};

type CartItemProps = Readonly<
  {
    order: RegistryOrderFragment;
    product: CookedProduct;
    itemQuantity: number;
    openModal: (productId: string, modalStateToBeOpened?: ModalStates) => void;
  } & Pick<ShoppingCartCommonProps, 'paths' | 'eventId'>
>;

export const ReservedItem: React.FC<CartItemProps> = ({ product, paths, itemQuantity, eventId, order, openModal }) => {
  const {
    siteName,
    priceSpanMarkup,
    orderPrice,
    donationFundTitle,
    handleClickCancel,
    handleRemoveClick,
    isDonationFundItem,
    shoppingCartProp,
    removeItemReason,
    showCancelConfirmation,
    toastVisible,
    toast,
    setToastVisibility,
    handleClickPurchase
  } = useReservedItemController({
    order,
    product,
    itemQuantity,
    orderId: order.id,
    eventId,
    paths,
    isDropship: false
  });
  const { t } = useTranslation('guestRegistry');
  const { quantity, continuePurchase, markPurchased, cancelReservation, markAsSent, hideWarningTitle, hideWarning } = t('shoppingCart');
  const host = useRegistryHost();
  const { updateDataProvider } = useGuestRegistryState();

  const handleUpdateDataProvider = useCallback(() => {
    updateDataProvider({
      type: isDonationFundItem ? 'initial' : 'purchased',
      productId: product.id,
      quantity: Math.max(itemQuantity ? itemQuantity / 100 : 0, 0),
      order: {
        orderId: order.id
      },
      guest: {
        name: '',
        email: ''
      }
    });
  }, [isDonationFundItem, itemQuantity, order.id, product.id, updateDataProvider]);

  const openModalLocal = () => {
    handleClickPurchase();
    handleUpdateDataProvider();
    openModal(product.id);
  };

  const openConfirmPurchaseModal = () => {
    handleUpdateDataProvider();
    openModal(product.id, ModalStates.CONFIRM_PURCHASED);
  };

  return (
    <>
      <NotificationToast
        isPositionTop={true}
        notificationText={toast.text}
        noAnimation={!toast.showConfettiAnimation}
        notificationVisible={toastVisible}
        setNotificationVisible={setToastVisibility}
      />
      <StyledItemBox padding={15} margin="2px">
        <Box display="grid" gridTemplateColumns="0.5fr 1fr 0.5fr" alignItems="center" gridGap="2px">
          <Box textAlign="center" backgroundColor={'mono2'} width="70px" height="70px">
            <StyledProductPhoto backgroundImage={addRendition({ url: product.image, renditionSize: 'medium', isProduct: true })} />
          </Box>
          <Box>
            <TextV2 typographyVariant="label1">{siteName}</TextV2>
            <StyledTitleText typographyVariant="hed2" component="p" __css={styles.titleText}>
              {product.title}
            </StyledTitleText>
            {!isDonationFundItem && !product.isGroupGiftingEnabled && <TextV2 typographyVariant="label1">{quantity({ quantity: itemQuantity, price: priceSpanMarkup })}</TextV2>}
          </Box>
          <TextV2 textAlign="center" typographyVariant="body1">
            {orderPrice}
          </TextV2>
        </Box>
        <Flex alignItems="center" justifyContent="center" marginY={6}>
          {product.isHidden ? (
            <Box __css={styles.hideBoxStyles} padding={6} alignItems="center">
              <TextV2 typographyVariant="hed1">{hideWarningTitle()}</TextV2>
              {host && (
                <TextV2 typographyVariant="body1" marginTop={3}>
                  {hideWarning({ host })}
                </TextV2>
              )}
            </Box>
          ) : (
            <StyleButton intent="secondary" typographyVariant={'button2'} fill={false} onClick={openModalLocal}>
              {isDonationFundItem ? donationFundTitle : continuePurchase()}
            </StyleButton>
          )}
        </Flex>
        {!showCancelConfirmation && (
          <Flex alignItems="center" justifyContent="space-between" marginTop={3} marginBottom={1}>
            <LinkV2 textDecoration="none" color="green7" onClick={openConfirmPurchaseModal}>
              <StyleActionText typographyVariant="body1" display="flex" alignItems="center" color={'green7'}>
                <CheckIcon />
                {isDonationFundItem ? markAsSent() : markPurchased()}
              </StyleActionText>
            </LinkV2>
            <LinkV2 textDecoration="none" onClick={handleClickCancel} color="typeSecondaryDark">
              <StyleActionText typographyVariant="body1" display="flex" alignItems="center" isCancel={true} color={'#595959'}>
                <CloseIcon />
                {cancelReservation()}
              </StyleActionText>
            </LinkV2>
          </Flex>
        )}
      </StyledItemBox>
      {showCancelConfirmation && (
        <CancelItemConfirmation
          productId={product.id}
          handleRemoveClick={handleRemoveClick}
          handleClickCancel={handleClickCancel}
          shoppingCartProp={shoppingCartProp}
          removeItemReason={removeItemReason || ''}
        />
      )}
    </>
  );
};

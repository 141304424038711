import {
  useDeleteRegistryOrderMutation,
  RegistryItemFragment,
  usePurchaseRegistryOrderMutation,
  DonationFundPlatformTypeEnum,
  useCreateSingleRegistryItemOrderMutation
} from '@graphql/generated';
import { useGuestRegistryTelemetry } from '../../GuestRegistry.telemetry';
import { useState, useEffect, useCallback } from 'react';
import { useGuestRegistryState } from '../../state';
import { useRegistryGuestTranslations } from '../GuestRegistry/GuestRegistry.i18n';
import { useHistory, useLocation } from '@react-router';
import { addRendition } from '@shared/utils/photoRendition';
import queryString from 'query-string';
import { RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { getStoreName, openStoreRedirect } from '@apps/registry/common/util/storeHelpers';
import { removeItemFromPurchaseClickHistory } from '../../../common/components/ShoppingCart/utils/trackPurchaseClicks';
import { useTranslation } from '@shared/core';
import { useToast } from '@withjoy/joykit';

interface PurchaseControllerProps {
  isDonationFund?: boolean;
  isInModal: boolean;
  eventHandle: string;
  reservationUndoneCallback?: () => void;
  eventId?: string;
  registry: RegistryList;
  isAdmin: boolean;
  productId?: string;
}

export interface PurchaseFields {
  email: string;
  name: string;
  quantity: number;
}

type ReservationState = 'initial' | 'undo' | 'undoSuccess' | 'purchased' | 'error' | 'thanks' | 'confirm';

export const usePurchaseController = ({
  registry,
  eventId,
  isDonationFund,
  isInModal,
  eventHandle,
  reservationUndoneCallback,
  isAdmin,
  productId: productIdFromCart
}: PurchaseControllerProps) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const { type, order, productId: productIdFromGuestRegistryState, quantity, orders, updateDataProvider, guest } = useGuestRegistryState();
  const productId = productIdFromCart ? productIdFromCart : productIdFromGuestRegistryState;
  const [deleteRegistryOrder] = useDeleteRegistryOrderMutation({ refetchQueries: ['GetEventRegistriesAndOrders'] });
  const [purchaseRegistryOrder] = usePurchaseRegistryOrderMutation({ refetchQueries: ['GetEventRegistriesAndOrders'] });
  const orderId = order?.orderId || '';
  const { getPurchaseGiftTranslations } = useRegistryGuestTranslations();
  const { completeButton } = getPurchaseGiftTranslations();
  const orderInfo = orders?.find(order => order.id === orderId);

  const deriveReservationStateFromType = (type: string) => {
    switch (type) {
      case 'purchased':
        return 'purchased';
      case 'undo':
        return 'undo';
      case 'error':
        return 'error';
      case 'thanks':
        return 'thanks';
      case 'confirm':
        return 'confirm';
      default:
        return 'initial';
    }
  };

  const { t } = useTranslation('guestRegistry');
  const { errorMessage } = t('shoppingCart');

  const [reservationState, setReservationState] = useState<ReservationState>(deriveReservationStateFromType(type));
  const [prevReservationState, setPrevReservationState] = useState<ReservationState>(type === 'undo' ? 'purchased' : 'initial');
  const [isErrorState, setIsErrorState] = useState<boolean>(type === 'error');

  const handleReservationStateChange = useCallback(
    (newState: ReservationState) => {
      setPrevReservationState(reservationState);
      setReservationState(newState);
    },
    [reservationState]
  );

  let registryId = eventId || '';
  let filteredRegistry = registry?.find(item => item.id === registryId);
  let item: RegistryItemFragment | undefined;

  // Transferred items do not get added to the Standalone registry
  registry?.forEach(registry => {
    if (item) {
      return;
    }
    registry.items?.forEach(currentItem => {
      if (item) {
        return;
      }
      if (currentItem?.id === productId) {
        item = currentItem;
        filteredRegistry = registry;
        registryId = registry.id;
      }
    });
  });

  const platformType = item?.donationFund?.platform?.type;

  const isDonation =
    typeof isDonationFund === 'undefined'
      ? platformType === DonationFundPlatformTypeEnum.paypal ||
        platformType === DonationFundPlatformTypeEnum.other ||
        platformType === DonationFundPlatformTypeEnum.venmo ||
        platformType === DonationFundPlatformTypeEnum.cashapp
      : isDonationFund;

  useEffect(() => {
    // the item may be deleted but the order is still around.
    // Jake fixed the part where the order gets left around,
    // but there will still be a few orphaned orders in prod, so we still need to handle that on the front end
    if (!item || !productId) {
      setIsErrorState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, productId]);

  const url = (item?.productData ? item?.productData.checkoutUrl : (filteredRegistry || {}).urlV0204) || '';

  const registryImage = filteredRegistry?.imageUrlV0204 || '';

  const storeName = item?.storeName || getStoreName(item?.productData?.externalUrl);

  const photos = ((item?.productData?.photos || []).length > 0 && item?.productData?.photos[0]) || null;
  const productImage = photos && photos.url ? addRendition({ url: photos.url, renditionSize: 'large' }) : '';
  const productImageSmall = photos && photos.url ? addRendition({ url: photos.url, renditionSize: 'small' }) : '';
  const {
    undoReserveItem,
    buyRegistryItem,
    purchase: purchasePageTrack,
    contribute: contributePageTrack,
    cancelConfirm: undoPageTrack,
    shoppingCartMakePurchaseClick
  } = useGuestRegistryTelemetry();
  const pageTrack = isDonation ? contributePageTrack : purchasePageTrack;
  const { toast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const getTelemArgs = () => {
    return {
      productTitle: item?.productData?.title,
      registryItemId: item?.id,
      registryId,
      reservedQty: isDonation ? 1 : quantity * 100,
      priceValueInMinorUnits: isDonation ? quantity * 100 : item?.productData?.price?.valueInMinorUnits,
      priceCurrencyCode: isDonation ? item?.donationFund?.goalMonetaryValue?.currency?.code : item?.productData?.price?.currency?.code,
      itemType: item?.productData?.legacyType,
      isGroupGifting: !!item?.isGroupGiftingEnabled
    };
  };

  const handleCancel = () => {
    // eslint-disable-next-line compat/compat
    const params = new URLSearchParams(location.search);
    params.delete('cancel');
    const url = `${location.pathname}?${params.toString()}`;
    history.replace(url);
  };

  const handleUndoDone = () => {
    if (productId) {
      removeItemFromPurchaseClickHistory({ reservedOrderId: orderId, productId });
    }
    handleReservationStateChange('undoSuccess');
    reservationUndoneCallback && reservationUndoneCallback();
  };

  const handleUndo = async (methodText: string) => {
    const telemArgs = {
      ...getTelemArgs(),
      method: methodText
    };
    undoReserveItem(telemArgs);
    handleCancel();
    if (orderId) {
      try {
        const result = await deleteRegistryOrder({
          variables: {
            id: orderId
          }
        });
        if (result) {
          handleUndoDone();
        }
      } catch (error) {
        toast(errorMessage());
      }
    } else {
      handleUndoDone();
    }
  };

  const handleClickProduct = () => {
    const telemArgs = {
      ...getTelemArgs(),
      buttonLabel: completeButton(),
      redirectUrl: item?.productData?.checkoutUrl ?? '',
      action: 'redirect',
      method: isDonation ? 'contribute' : 'purchase'
    };
    buyRegistryItem(telemArgs);

    const donation = item?.donationFund?.donations.find(donation => donation.id === orderId);
    const amount = donation?.amount.floatingPointDecimalString || '0';

    openStoreRedirect({
      eventHandle,
      url,
      isDonation,
      platformType,
      amount,
      isMobileOrTablet,
      storeName
    });
  };

  const showUndoConfirmationScreen = useCallback(() => {
    handleReservationStateChange('undo');
    undoPageTrack.enter();
  }, [handleReservationStateChange, undoPageTrack]);

  const handleCancelUndo = () => {
    if (prevReservationState === reservationState) {
      history.goBack();
    }
    handleReservationStateChange(prevReservationState);
  };

  const onCreateAndPurchaseOrderError = () => {
    updateDataProvider({
      type: 'error',
      order: null,
      guest: null,
      quantity: 0
    });
  };

  const [createRegistryOrder] = useCreateSingleRegistryItemOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders'],
    onError: onCreateAndPurchaseOrderError
  });

  const createOrderAndPurchase = async (isPurchased: boolean) => {
    if (item?.id && guest) {
      const { data } = await createRegistryOrder({
        variables: {
          payload: {
            registryItemId: item.id,
            name: guest.name,
            email: guest.email,
            quantity,
            isPurchased
          }
        }
      });
      const orderId = data?.createSingleRegistryItemOrder.model?.id;
      if (orderId) {
        updateDataProvider({
          type: 'purchased',
          productId: productId,
          order: {
            orderId: orderId
          },
          guest: {
            name: guest.name,
            email: guest.email
          },
          quantity
        });
      }
    }
  };

  const purchaseOrder = useCallback(async () => {
    // check if the url has the orderId, if not, get orderId from state.
    const purchaseOrderId = (params.orderId as string) || '';
    const id = orderId ? orderId : purchaseOrderId;
    await purchaseRegistryOrder({ variables: { id } });
    if (productId) {
      removeItemFromPurchaseClickHistory({ reservedOrderId: id, productId });
    }
  }, [params, purchaseRegistryOrder, orderId, productId]);

  const handleConfirmationClick = () => {
    handleReservationStateChange('purchased');
    if (!orderId) {
      createOrderAndPurchase(true);
    } else {
      purchaseOrder();
    }
    const telemArgs = {
      eventId: registryId,
      productId: item?.id,
      registryItemId: item?.id,
      registryId,
      reservedQty: isDonation ? 1 : quantity * 100,
      priceValueInMinorUnits: isDonation ? quantity * 100 : item?.productData?.price?.valueInMinorUnits,
      priceCurrencyCode: isDonation ? item?.donationFund?.goalMonetaryValue?.currency?.code : item?.productData?.price?.currency?.code,
      destinationUrl: item?.productData?.checkoutUrl,
      typeOfItem: item?.productData?.legacyType,
      buttonLabel: 'Purchase Confirmation screen',
      productTitle: item?.productData?.title,
      giftGiverName: orderInfo?.name,
      giftGiverEmail: orderInfo?.email
    };
    shoppingCartMakePurchaseClick(telemArgs);
  };

  useEffect(() => {
    pageTrack.enter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleUndo,
    handleClickProduct,
    handleConfirmationClick,
    type,
    orderId,
    isAdmin: isAdmin || false,
    registryImage,
    storeName,
    productImage,
    productImageSmall,
    item,
    isDonation,
    platformType,
    quantity,
    reservationState,
    showUndoConfirmationScreen,
    handleCancelUndo,
    isErrorState,
    createOrderAndPurchase
  };
};

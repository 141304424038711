import { useMemo } from 'react';

export const getCatalogItemId = (product: { pdpUrl: string }) => {
  // Since we don't receive catalog item id - need to retrieve it from pdpUrl
  const pattern = /shop\/item\/(.+)/;
  const matches = product.pdpUrl.match(pattern);

  return matches?.length && matches[1] ? matches[1] : undefined;
};

export const useGetCatalogItemId = (product: { pdpUrl: string }) => {
  return useMemo(() => getCatalogItemId({ pdpUrl: product.pdpUrl }), [product.pdpUrl]);
};

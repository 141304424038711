import React from 'react';

import { useTranslation } from '@shared/core';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { SpacingStack, TextV2, Flex, Box } from '@withjoy/joykit';

import { useShoppingCart } from '@apps/registry/common/components/ShoppingCart/state';
import { CartFooterSubmitButton } from '@apps/registry/common/components/ShoppingCart/components';
import { DetailsForm } from './DetailsForm';
import { FieldInputProps } from 'formik';

import { CartCheckoutSteps } from '../../../ShoppingCart.types';
import { StyledTextV2, styles } from './DetailsStep.styles';

type DetailsStepProps = {
  saveData: () => void;
  formFields: {
    getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
    errors: Record<string, string | undefined>;
  };
  disableNextStep?: boolean;
};

export const DetailsStep = ({ saveData, formFields, disableNextStep }: DetailsStepProps) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  const { t2 } = useTranslation('guestRegistry');
  const { shippingMethod: shippingMethodLabel, shippingAddress: shippingAddressLabel, giftEmoji, editShippingAddress, freeShipping, standardShipping } = t2(
    'shoppingCart',
    'detailsStep'
  );

  const {
    state: { shippingAddress, shippingFee },
    mutators: { updateCurrentCheckoutStep }
  } = useShoppingCart();

  return (
    <SpacingStack spacing={6} width="100%">
      <Flex flexDirection="column">
        <TextV2 typographyVariant="hed1" marginBottom={3}>
          {shippingMethodLabel}
        </TextV2>
        <TextV2 typographyVariant="body1">{shippingFee === '0.00' ? freeShipping : standardShipping}</TextV2>
      </Flex>
      <div>
        <Flex marginBottom={3} alignItems="end">
          <TextV2 typographyVariant="hed1">{shippingAddressLabel}</TextV2>
          <StyledTextV2 typographyVariant="label3" onClick={() => updateCurrentCheckoutStep(CartCheckoutSteps.Shipping)} __css={styles.textV2Styles}>
            {editShippingAddress}
          </StyledTextV2>
        </Flex>
        {shippingAddress && (
          <Flex alignItems="center">
            <SpacingStack spacing={1}>
              <Flex alignItems="center">
                <TextV2 typographyVariant="button1">
                  {giftEmoji} {shippingAddress.name}
                </TextV2>
              </Flex>

              <TextV2 typographyVariant="label2" color="mono10" textAlign="left">
                {`${shippingAddress?.city}, ${shippingAddress?.state}, ${shippingAddress?.postalCode}`}
              </TextV2>
            </SpacingStack>
          </Flex>
        )}
        <Box marginTop={6}>
          <DetailsForm formFields={formFields} />
        </Box>
        {!isMobileOrTablet && <CartFooterSubmitButton saveData={saveData} disabled={disableNextStep} />}
      </div>
    </SpacingStack>
  );
};

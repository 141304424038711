import { PDPSection } from '@apps/registry/common/components/Catalog/components/PdpSections/Sections/PdpSection/PdpSections.utils';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { Box, Divider, Flex } from '@withjoy/joykit';
import React, { useMemo } from 'react';

import { DetailSections } from '@apps/registry/common/components/Catalog/components/PdpSections/Sections/DetailSections/DetailSections';
import { CoupleNote } from '../CoupleNote';
import { RegistryValues } from '../RegistryValues';
import { styles } from './DropshipProductDetails.styles';
import { useFeatureValue } from '@shared/core/featureFlags';
import { RegistryValuesV2 } from '../RegistryValues/RegistryValues';
import { useGetCatalogItemId } from '@apps/registry/common/components/Catalog/hooks/productPdp';

type Props = {
  product: CookedProduct;
  pdpProductProp?: Maybe<{
    pdpSections?: Array<PDPSection>;
  }>;
  eventPhoto?: string;
  isLoading: boolean;
  canRenderProductValues: boolean;
};

export const DropshipProductDetails = ({ pdpProductProp, product, eventPhoto, canRenderProductValues, isLoading }: Props) => {
  const catalogItemId = useGetCatalogItemId(product);

  const detailsSections = useMemo(() => {
    return pdpProductProp?.pdpSections?.filter(section => section.sectionType === 'details');
  }, [pdpProductProp?.pdpSections]);

  const canRenderDetailsAccordion = catalogItemId && detailsSections?.length && !isLoading;

  const featureRegistryFlatShippingFeeEnabled = useFeatureValue('registryFlatShippingFeeEnabled').value === 'on';

  if (!isLoading) {
    return (
      <Flex flexDirection="column" alignItems="center" __css={styles.dialogDetailsWrapper}>
        {product.note && (
          <div>
            <CoupleNote product={product} eventPhoto={eventPhoto} />
          </div>
        )}
        {canRenderProductValues && <>{featureRegistryFlatShippingFeeEnabled ? <RegistryValuesV2 /> : <RegistryValues />}</>}
        {canRenderDetailsAccordion && (
          <Box __css={styles.detailsAccordionContainer}>
            <Divider />
            <Box __css={styles.detailsAccordionStyles}>
              <DetailSections sections={detailsSections} />
            </Box>
          </Box>
        )}
      </Flex>
    );
  }
  return null;
};

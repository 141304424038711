import React, { useContext, useEffect, useState, useCallback } from 'react';
import { RegistryLineItemInput, useCreateRegistryPurchaseContextMutation } from '@graphql/generated';
import { withWindow } from '@shared/utils/withWindow';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';

import { useShoppingCart } from '@apps/registry/common/components/ShoppingCart/state';
import { CartCheckoutSteps, LineItem, ShippingAddressOptions } from '../../../ShoppingCart.types';
import { formatAddress } from '../../../state/ShoppingCart.utils';
import { StyledLoader, StyledLoadingWrapper, styles } from './PaymentStep.styles';
import { useTheme } from '@withjoy/joykit';
import { AnalyticsContext, useTranslation } from '@shared/core';
import { PaymentErrorScreen } from './PaymentErrorScreen';
import { CheckoutFailureCode } from '../../../utils/constants';
import { ReactComponent as EmptyCartIcon } from '@apps/registry/admin/assets/empty-cart.svg';
import { ReactComponent as MissingGift } from '@apps/registry/admin/assets/missing-gift.svg';
import { ReactComponent as BadAddress } from '@apps/registry/admin/assets/Shipping.svg';

const formatList = (itemList: Array<LineItem>): Array<RegistryLineItemInput> => itemList.map(item => ({ quantity: item.quantity, registryItemId: item.registryItemId }));

type PaymentStepProps = {
  eventId: string;
};

export const PaymentStep = ({ eventId }: PaymentStepProps) => {
  const {
    state: { itemList, shippingAddress, details, hadOnlyStillNeededItems, selectedShippingOption, verificationStatus },
    mutators: { updateCurrentCheckoutStep, setVerificationStatus }
  } = useShoppingCart();
  const routes = useRoutePaths();
  const { colors } = useTheme();
  const [createPurchaseContext] = useCreateRegistryPurchaseContextMutation();
  const hasNotNeededError = !hadOnlyStillNeededItems;
  const [hasCheckoutError, setHasCheckoutError] = useState('');
  const { t2 } = useTranslation('guestRegistry');
  const { checkoutErrorTitle, checkoutErrorAction, oos, badAddress, registryError, checkoutErrorText } = t2('shoppingCart', 'paymentStep');

  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    setVerificationStatus('started');
  }, [setVerificationStatus]);

  const handleCheckoutError = useCallback(
    (checkoutError?: string) => {
      if (checkoutError) {
        setHasCheckoutError(checkoutError);
      }
      analytics?.track({
        category: 'guest.registry',
        action: 'CheckoutFailed',
        extraInfo: {
          error: checkoutError || 'networkError'
        }
      });
    },
    [analytics, setHasCheckoutError]
  );

  useEffect(() => {
    if (!shippingAddress || !details || !details.email) {
      return;
    }

    if (verificationStatus !== 'done') {
      return;
    }

    if (hasNotNeededError || hasCheckoutError) {
      handleCheckoutError(hasCheckoutError);
      return;
    }

    (async () => {
      setVerificationStatus('idle');
      try {
        const failureUrlTemplate = withWindow(global => global.location.origin + routes.guestRegistry.path, '');
        const successUrlTemplate = `${withWindow(global => global.location.origin + routes.purchase.path, '')}?pcId=\${purchaseContextId}`;
        const purchaseContext = await createPurchaseContext({
          variables: {
            payload: {
              eventId: eventId!,
              name: details.name,
              email: details.email,
              note: details.message,
              lineItems: formatList(itemList),
              failureUrlTemplate,
              successUrlTemplate,
              address: selectedShippingOption === ShippingAddressOptions.CoupleAddress ? undefined : formatAddress(shippingAddress),
              eCard: details?.eCard ?? undefined
            }
          }
        });
        const checkoutUrl = purchaseContext?.data?.createRegistryPurchaseContext.checkoutUrl;
        const checkoutError = purchaseContext?.data?.createRegistryPurchaseContext.checkoutFailureCode;

        if (checkoutUrl && !checkoutError) {
          withWindow(global => (global.location.href = checkoutUrl!));
        } else {
          handleCheckoutError(checkoutError || 'serverError');
        }
      } catch (err) {
        handleCheckoutError();
      }
    })();
  }, [
    itemList,
    shippingAddress,
    details,
    createPurchaseContext,
    eventId,
    routes,
    hasCheckoutError,
    hasNotNeededError,
    selectedShippingOption,
    setVerificationStatus,
    verificationStatus,
    handleCheckoutError
  ]);

  if (hasCheckoutError) {
    let errorMessage;
    let checkoutErrorIcon;
    switch (hasCheckoutError) {
      case CheckoutFailureCode.OOS:
        errorMessage = oos;
        checkoutErrorIcon = <MissingGift />;
        break;
      case CheckoutFailureCode.BAD_ADDRESS:
        errorMessage = badAddress;
        checkoutErrorIcon = <BadAddress />;
        break;
      case CheckoutFailureCode.REGISTRY_ERROR:
        errorMessage = registryError;
        checkoutErrorIcon = <EmptyCartIcon />;
        break;
      default:
        errorMessage = checkoutErrorText;
        checkoutErrorIcon = <EmptyCartIcon />;
    }
    return (
      <PaymentErrorScreen
        icon={checkoutErrorIcon}
        title={checkoutErrorTitle}
        text={errorMessage}
        action={checkoutErrorAction}
        handleAction={() => {
          updateCurrentCheckoutStep(CartCheckoutSteps.Cart);
        }}
      />
    );
  }

  return (
    <StyledLoadingWrapper __css={styles.loadingWrapperStyles}>
      <svg width={52} height={52} x="0px" y="0px" viewBox="0 0 52 52">
        <circle strokeWidth={4} strokeLinecap="round" r={18} cx={26} cy={26} stroke={colors.mono4} fill="transparent" />
        <StyledLoader strokeWidth={4} strokeLinecap="round" r={18} cx={26} cy={26} />
      </svg>
    </StyledLoadingWrapper>
  );
};

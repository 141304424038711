import React, { useCallback } from 'react';

import { RegistryOrderFragment } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { ButtonV2, Flex, TextV2 } from '@withjoy/joykit';

import { NotificationToast } from '@apps/registry/common/components/NotificationToast';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { ModalStates } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';
import { CancelItemConfirmationProps, ShoppingCartCommonProps } from '@apps/registry/common/components/ShoppingCart';
import { useReservedItemController } from '@apps/registry/common/components/ShoppingCart/ShoppingCartItem.controller';

import { StyledItemBox, StyleCheckbox, StyleRemoveButton, StyleLink, ReservedItemBox } from './ReservedItemV2.styles';
import { InReceiptItem } from '../../InReceiptItem';

const CancelItemConfirmation: React.FC<CancelItemConfirmationProps> = ({ shoppingCartProp, handleRemoveClick, handleClickCancel, removeItemReason, productId }) => {
  const { t } = useTranslation('guestRegistry');
  const { removeTitle, removeItem, cancel } = t('shoppingCart');

  return (
    <Flex alignItems="center" justifyContent="center">
      <StyledItemBox width="100%" padding="0px 40px">
        <TextV2 typographyVariant="body1" marginY={6} fontWeight={600}>
          {removeTitle()}
        </TextV2>
        {shoppingCartProp.removeAction.map(({ value, label, onChange }) => (
          <StyleCheckbox key={value} label={label} id={`${value}-${productId}`} name={productId} value={value} checked={removeItemReason === value} onChange={onChange} />
        ))}
        <Flex alignItems="center" justifyContent="center" marginY={6}>
          <StyleRemoveButton onClick={handleRemoveClick}>{removeItem()}</StyleRemoveButton>
        </Flex>
        <Flex alignItems="center" justifyContent="center" marginY={6}>
          <StyleLink underline="none" onClick={handleClickCancel} color={'typeSecondaryDark'} isCancel={true}>
            <TextV2 typographyVariant="body1" display="flex" alignItems="center" color={'typeSecondaryDark'}>
              {cancel()}
            </TextV2>
          </StyleLink>
        </Flex>
      </StyledItemBox>
    </Flex>
  );
};

type ReservedItemV2Props = Readonly<
  {
    order: RegistryOrderFragment;
    product: CookedProduct;
    itemQuantity: number;
    openModal: (productId: string, modalStateToBeOpened?: ModalStates) => void;
  } & Pick<ShoppingCartCommonProps, 'paths' | 'eventId'>
>;

export const ReservedItemV2: React.FC<ReservedItemV2Props> = ({ product, paths, itemQuantity, eventId, order, openModal }) => {
  const {
    siteName,
    totalPrice,
    price,
    handleClickCancel,
    handleRemoveClick,
    isDonationFundItem,
    shoppingCartProp,
    removeItemReason,
    showCancelConfirmation,
    toastVisible,
    toast,
    setToastVisibility,
    handleClickPurchase
  } = useReservedItemController({
    order,
    product,
    itemQuantity,
    orderId: order.id,
    eventId,
    paths,
    isDropship: false
  });

  const { t } = useTranslation('guestRegistry');
  const { cancelReservation, buyNow, alreadyPurchased, giveNow, alreadyGave } = t('shoppingCart');

  const { updateDataProvider } = useGuestRegistryState();

  const handleUpdateDataProvider = useCallback(() => {
    updateDataProvider({
      type: isDonationFundItem ? 'initial' : 'purchased',
      productId: product.id,
      quantity: Math.max(itemQuantity ? itemQuantity / 100 : 0, 0),
      order: {
        orderId: order.id
      },
      guest: {
        name: '',
        email: ''
      }
    });
  }, [isDonationFundItem, itemQuantity, order.id, product.id, updateDataProvider]);

  const openModalLocal = () => {
    handleClickPurchase();
    handleUpdateDataProvider();
    openModal(product.id);
  };

  const openConfirmPurchaseModal = () => {
    handleUpdateDataProvider();
    openModal(product.id, ModalStates.CONFIRM_PURCHASED);
  };

  return (
    <ReservedItemBox>
      <NotificationToast
        isPositionTop={true}
        notificationText={toast.text}
        noAnimation={!toast.showConfettiAnimation}
        notificationVisible={toastVisible}
        setNotificationVisible={setToastVisibility}
      />
      <InReceiptItem product={product} quantity={itemQuantity} price={price} totalPrice={totalPrice} isDonationFundItem={isDonationFundItem} siteName={siteName} />
      <StyledItemBox>
        {!product.isHidden && (
          <ButtonV2 intent="neutral" width="100%" onClick={openModalLocal}>
            {isDonationFundItem ? giveNow() : buyNow()}
          </ButtonV2>
        )}
        {!showCancelConfirmation && (
          <>
            <ButtonV2 intent="neutral" variant="outline" width="100%" onClick={openConfirmPurchaseModal}>
              {isDonationFundItem ? alreadyGave() : alreadyPurchased()}
            </ButtonV2>
            <ButtonV2 intent="neutral" variant="ghost" width="100%" onClick={handleClickCancel}>
              {cancelReservation()}
            </ButtonV2>
          </>
        )}
      </StyledItemBox>
      {showCancelConfirmation && (
        <CancelItemConfirmation
          productId={product.id}
          handleRemoveClick={handleRemoveClick}
          handleClickCancel={handleClickCancel}
          shoppingCartProp={shoppingCartProp}
          removeItemReason={removeItemReason || ''}
        />
      )}
    </ReservedItemBox>
  );
};

import { addRendition } from '@shared/utils/photoRendition';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { StyledCustomCursor, StyledPhotoContainer, StyledProductPhoto, styles } from './ZoomablePhoto.styles';
import CustomCursor from '../../assets/custom-cursor.svg';
import { ReactComponent as CustomCursorIcon } from '../../assets/custom-cursor.svg';
import { isPhotoMediaAsset } from '../../../ProductImageGallery/utils';
import { CatalogProductMediaAsset } from '@apps/registry/common/components/Catalog/Catalog.types';

const ZOOM_STEP = 1;

export const ZoomablePhoto = ({
  item,
  setEnableSwipe,
  isMobile,
  isSelected,
  wasZoomedIn,
  setWasZoomedIn,
  maxHeight
}: {
  item: CatalogProductMediaAsset;
  setEnableSwipe: (state: boolean) => void;
  isMobile?: boolean;
  isSelected?: boolean;
  wasZoomedIn?: boolean;
  setWasZoomedIn?: (state: boolean) => void;
  maxHeight: number;
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showZoomIcon, setShowZoomIcon] = useState(isMobile && !wasZoomedIn);
  const [panningPositionStart, setPanningPositionStart] = useState({ x: 0, y: 0 });
  const [panningPositionStop, setPanningPositionStop] = useState({ x: 0, y: 0 });

  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const handleZoomOut = useCallback(() => {
    if (transformComponentRef.current) {
      const { zoomOut } = transformComponentRef.current;
      zoomOut(ZOOM_STEP);
      setIsZoomed(false);
      setEnableSwipe(true);
    }
  }, [setEnableSwipe]);

  const handleZoomIn = useCallback(() => {
    if (transformComponentRef.current) {
      const { zoomIn } = transformComponentRef.current;
      zoomIn(ZOOM_STEP);
      setIsZoomed(true);
      setEnableSwipe(false);
      if (setWasZoomedIn) {
        setWasZoomedIn(true);
      }
    }
  }, [setWasZoomedIn, setEnableSwipe]);

  useEffect(() => {
    if (!isSelected) {
      handleZoomOut();
    }
  }, [handleZoomOut, isSelected]);

  useEffect(() => {
    setShowZoomIcon(isMobile && !wasZoomedIn);
  }, [wasZoomedIn, isMobile]);

  const handleClick = useCallback(() => {
    if (panningPositionStart.x !== panningPositionStop.x || panningPositionStart.y !== panningPositionStop.y) {
      return;
    }
    if (isZoomed) {
      handleZoomOut();
    } else {
      handleZoomIn();
    }
  }, [handleZoomIn, handleZoomOut, isZoomed, panningPositionStart, panningPositionStop]);

  const handleWheelZoom = (ref: ReactZoomPanPinchRef, event: WheelEvent) => {
    // @ts-ignore
    if (event?.wheelDelta < 0) {
      handleZoomOut();
    } else {
      handleZoomIn();
    }
  };

  const handlePanningStart = useCallback((ref, event) => {
    setIsDragging(true);
    setPanningPositionStart({ x: event.x, y: event.y });
  }, []);

  const handlePanningStop = useCallback((ref, event) => {
    setIsDragging(false);
    setPanningPositionStop({ x: event.x, y: event.y });
  }, []);

  const cursorType = useMemo(() => {
    if (isDragging) {
      return 'grabbing';
    }
    if (isZoomed) {
      return 'grab';
    }
    return `url(
        '${CustomCursor}'
      ) 22 22,
      pointer`;
  }, [isDragging, isZoomed]);

  if (!isPhotoMediaAsset(item)) {
    return null;
  }

  return (
    <TransformWrapper
      ref={transformComponentRef}
      centerOnInit
      minScale={ZOOM_STEP}
      maxScale={ZOOM_STEP + 1}
      pinch={{ disabled: true }}
      panning={{ disabled: !isZoomed }}
      doubleClick={{ disabled: true }}
      onPanningStart={handlePanningStart}
      onPanningStop={handlePanningStop}
      onWheelStop={handleWheelZoom}
    >
      <TransformComponent wrapperStyle={{ width: 'inherit', height: 'inherit' }} contentStyle={{ width: 'inherit', height: 'inherit' }}>
        <StyledPhotoContainer __css={styles.photoContainer} cursor={cursorType} onClick={handleClick}>
          <StyledProductPhoto src={`${addRendition({ url: item?.photo?.url, renditionSize: 'medium' })}`} isMobile={isMobile} maxHeight={maxHeight} />
          {showZoomIcon && (
            <StyledCustomCursor __css={styles.customCursor}>
              <CustomCursorIcon />
            </StyledCustomCursor>
          )}
        </StyledPhotoContainer>
      </TransformComponent>
    </TransformWrapper>
  );
};

import React, { useCallback, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ChatIcon } from '@assets/icons';
import { Box, ButtonV2, Divider, Flex, OptionType, SelectV1, TextV2 } from '@withjoy/joykit';

import { FlowVariant } from '@apps/registry/guest/components/CheckoutDialog/machines';
import { LayoutShell } from '@apps/registry/guest/components/CheckoutDialog/components';
import { usePurchaseDialogTranslations } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/hooks/usePurchaseDialogTranslations';
import { useCheckoutFunnel, useCheckoutTelemetryData, useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { isPricedProduct as checkIfIsPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useManagedGiftReservation } from '@apps/registry/guest/routes/GuestRegistry';
import {
  StyledCTAContainer,
  StyledDescriptionTitleContainer,
  StyledPriceSummaryContainer,
  StyledProductDescriptionContainer,
  StyledProductPrice,
  StyledProductTitle,
  StyledQuantityContainer,
  StyledQuantityInfoWrapper,
  StyledSelectWrapper,
  styles
} from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/DropshipProductDialogBody/components/ProductInformation/ProductInformation.styles';
import { LaunchEmailSupportLink } from '@apps/registry/guest/components';
import { GifterDetailsSelectors } from '@apps/registry/guest/components/CheckoutDialog/routes/GifterDetails/GifterDetails.selectors';
import { productImageStyles, StyledProductImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/LayoutShell/LayoutShell.styles';
import { CoupleNote } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/CoupleNote/CoupleNote';

import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { addAction } from '@shared/utils/logger';
import { ProductInformationGroupGifting } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/DropshipProductDialogBody/components/ProductInformation/ProductInformationGroupGifting';
import MustHaveChip from '../../components/MustHaveChip';
import { useTranslation } from '@shared/core';
import { DROPDOWN_QUANTITY_LIMIT } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/DropshipProductDialogBody/components/ProductInformation/ProductInformation';
import { ItemCounter } from '@apps/registry/common/components/ShoppingCart/ShoppingCartInnerV2/steps/CartReviewStep/components/InCartSection/components/InCartItem/ItemCounter';

export const GiftPdp = () => {
  const {
    formButtonMarkAsPurchased,
    hideDescription,
    readMore,
    questionLinkText,
    needed,
    alreadyReservedTitle,
    alreadyReservedSubtitle,
    alreadyReservedCheckEmail,
    formButtonBuyNow,
    requiredLabel,
    quantityTitle,
    quantityTimesPrice,
    subtotalTitle
  } = usePurchaseDialogTranslations();

  const [flowVariant, setFlowVariant] = useState<FlowVariant>('full');

  const {
    submitGiftPdpForm,
    formValues,
    updateDonationAmount,
    context: { isGroupGifting }
  } = useCheckoutFunnel(({ submitGiftPdpForm, formValues, updateDonationAmount, context }) => [submitGiftPdpForm, formValues, updateDonationAmount, context.isGroupGifting]);

  const { formatCurrency } = useCurrencyFormatter();
  const { product, productImageSrc, siteName, currency } = useGetProductData('giftPdp');
  const { t: translations } = useTranslation('catalogRegistry');
  const { message } = translations('salesPrice');
  const { affiliateBuyNow, alreadyReservedGiftViewed } = useGuestRegistryTelemetry();
  const { eventId = '', registryItemId, productId } = useCheckoutTelemetryData();

  const { recentGiftStatus } = useManagedGiftReservation({
    product
  });

  const [isDescriptionShowed, setIsDescriptionShowed] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      quantity: formValues.quantity || 1
    },
    validationSchema: Yup.object({
      quantity: Yup.number().min(1).required(requiredLabel())
    }),
    onSubmit: values => {
      const affiliateBuyNowData = {
        eventId,
        registryItemId,
        externalUrl: product?.externalUrl,
        productId,
        productTitle: product?.title,
        registryId: eventId,
        checkoutMechanism: 'externalNavigation',
        priceValueInMinorUnits: (product?.numberPrice || 0) * 100,
        buttonLabel: formButtonBuyNow(),
        reservedQty: formik.values.quantity
      };
      affiliateBuyNow(affiliateBuyNowData);
      addAction('affiliateBuyNow', affiliateBuyNowData);
      submitGiftPdpForm({ quantity: values.quantity, flowVariant: flowVariant });
    }
  });

  const stillNeededOptions: OptionType[] =
    product && product.stillNeeded >= 1 ? Array.from(Array(product.stillNeeded).keys()).map(i => ({ label: `${i + 1}`, value: `${i + 1}` })) : [];

  const onSubmit = useCallback(
    (flowVariant: FlowVariant) => {
      setFlowVariant(flowVariant);
      void formik.submitForm();
    },
    [formik]
  );

  const totalPrice = formatCurrency({
    priceFloatingPointDecimalString: (formik.values.quantity * (product?.numberPrice || 0)).toString(),
    priceCurrencyCode: currency,
    formatForm: 'short',
    shouldCalculateCharmPrice: true
  });

  const fullPrice = formatCurrency({
    priceFloatingPointDecimalString: product?.fullPrice || '',
    priceCurrencyCode: product?.fullPriceCurrencyCode,
    formatForm: 'short',
    shouldCalculateCharmPrice: true
  });

  useEffect(() => {
    if (recentGiftStatus?.alreadyReserved) {
      alreadyReservedGiftViewed({ label: recentGiftStatus.usedEmail });
    }
  }, [alreadyReservedGiftViewed, recentGiftStatus?.alreadyReserved, recentGiftStatus?.usedEmail]);

  return (
    <LayoutShell {...GifterDetailsSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement="static" />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>{productImageSrc && <StyledProductImage __css={productImageStyles(productImageSrc)} />}</LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          {product?.mustHave && <MustHaveChip />}
          <Box>
            <TextV2 typographyVariant="hed3" marginBottom={1}>
              {product?.brand ?? siteName}
            </TextV2>
            <StyledProductTitle typographyVariant="hed5" marginBottom={3}>
              {product?.title}
            </StyledProductTitle>
            {checkIfIsPricedProduct(product) && (
              <Flex gap={2}>
                <StyledProductPrice typographyVariant="hed4" textDecoration={!product.isFullPrice ? 'line-through' : ''} color="mono13">
                  {!product.isFullPrice ? fullPrice : product.wholeNumPrice}
                </StyledProductPrice>
                {!product?.isFullPrice && (
                  <TextV2 typographyVariant="hed4" color="#D80B0B">
                    {message()} {product.wholeNumPrice}
                  </TextV2>
                )}
              </Flex>
            )}
            {product?.description && (
              <StyledProductDescriptionContainer marginTop={5}>
                <StyledDescriptionTitleContainer __css={styles.description(isDescriptionShowed)}>
                  <TextV2 typographyVariant={'body2'}>{product.description}</TextV2>
                </StyledDescriptionTitleContainer>
                <ButtonV2
                  variant="link"
                  intent="productive"
                  typographyVariant="body1"
                  lineHeight="tall"
                  width="fit-content"
                  onClick={() => setIsDescriptionShowed(!isDescriptionShowed)}
                >
                  {isDescriptionShowed ? hideDescription() : readMore()}
                </ButtonV2>
              </StyledProductDescriptionContainer>
            )}
            <Flex justifyContent="center" flexDirection="column">
              {recentGiftStatus?.alreadyReserved ? (
                <>
                  <Divider marginY={6} />
                  <Box>
                    <TextV2 typographyVariant="hed4" paddingRight={2}>
                      {alreadyReservedTitle()}
                    </TextV2>
                    <TextV2 typographyVariant="body1" marginTop={7} paddingRight={2}>
                      {alreadyReservedSubtitle()}
                    </TextV2>
                    <TextV2 typographyVariant="body1" marginTop={7}>
                      {alreadyReservedCheckEmail({ email: recentGiftStatus.usedEmail })}
                    </TextV2>
                  </Box>
                </>
              ) : (
                <>
                  {!!product?.requested && product?.requested > 1 && (
                    <>
                      <Divider marginY={6} />
                      <StyledQuantityContainer __css={styles.quantityContainer}>
                        <StyledQuantityInfoWrapper __css={styles.quantityInfoWrapper}>
                          <TextV2 color="mono14" typographyVariant="hed1">
                            {quantityTitle()}
                          </TextV2>
                          <TextV2 color="mono10" typographyVariant="label2">
                            {needed({ StillNeeded: product?.stillNeeded })}
                          </TextV2>
                        </StyledQuantityInfoWrapper>
                        <StyledSelectWrapper __css={styles.selectWrapper}>
                          {product?.requested > DROPDOWN_QUANTITY_LIMIT ? (
                            <ItemCounter
                              increaseCounter={() => {
                                formik.setFieldValue('quantity', formik.values.quantity + 1);
                              }}
                              disableIncrease={formik.values.quantity === product?.stillNeeded}
                              decreaseCounter={() => {
                                formik.setFieldValue('quantity', formik.values.quantity - 1);
                              }}
                              counter={formik.values.quantity}
                              disableRemoveOption={true}
                            />
                          ) : (
                            <SelectV1
                              defaultValue={stillNeededOptions[0]}
                              searchable={false}
                              options={stillNeededOptions}
                              fluid={false}
                              onChange={e => {
                                formik.setFieldValue('quantity', Number.parseInt(e?.value || '1'));
                              }}
                            />
                          )}
                        </StyledSelectWrapper>
                      </StyledQuantityContainer>
                    </>
                  )}

                  {checkIfIsPricedProduct(product) && !isGroupGifting && (
                    <>
                      <Divider marginY={6} />
                      <StyledPriceSummaryContainer __css={styles.priceSummaryContainer}>
                        <Flex justifyContent="flex-end">
                          <TextV2 color="mono10" typographyVariant="body1">
                            {quantityTimesPrice({ quantity: formik.values.quantity, price: product.wholeNumPrice })}
                          </TextV2>
                        </Flex>
                        <Flex justifyContent="space-between">
                          <TextV2 color="mono14" typographyVariant="hed1">
                            {subtotalTitle()}
                          </TextV2>
                          <TextV2 color="mono14" typographyVariant="hed1">
                            {totalPrice}
                          </TextV2>
                        </Flex>
                      </StyledPriceSummaryContainer>
                    </>
                  )}
                  <StyledCTAContainer __css={styles.ctaContainer} marginTop={8}>
                    {!isGroupGifting || (isGroupGifting && (product?.donationFund?.received || 0) <= 0) ? (
                      <>
                        <ButtonV2 intent="neutral" shape="rounded" onClick={() => onSubmit('full')}>
                          {formButtonBuyNow()}
                        </ButtonV2>
                        <ButtonV2 type="button" intent="neutral" variant="ghost" color="mono14" onClick={() => onSubmit('deferred')}>
                          {formButtonMarkAsPurchased()}
                        </ButtonV2>
                      </>
                    ) : null}
                    {isGroupGifting && product && (
                      <ProductInformationGroupGifting
                        product={product}
                        handleOnBuyNowClick={() => onSubmit('full')}
                        cannotPurchase={false}
                        handleCheckoutDialogOpenCallback={(donationAmount: number) => updateDonationAmount({ donationAmount })}
                      />
                    )}
                  </StyledCTAContainer>
                  <Box marginTop={6}>
                    {
                      <LaunchEmailSupportLink>
                        <ChatIcon />
                        {questionLinkText()}
                      </LaunchEmailSupportLink>
                    }
                  </Box>
                </>
              )}
            </Flex>
          </Box>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
      {product?.note && (
        <Flex justifyContent="center">
          <CoupleNote product={product} />
        </Flex>
      )}
    </LayoutShell>
  );
};
